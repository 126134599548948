import { Injectable } from '@angular/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';

/** ngx-translate 키 없을 때 처리 방법 커스텀 */
@Injectable()
export class PwMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    // return params.key.split('.').pop();
    return '';
  }
}
