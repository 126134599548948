import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-not-receiving-notification',
  templateUrl: './email-not-receiving-notification.component.html',
  styleUrls: ['./email-not-receiving-notification.component.scss'],
})
export class EmailNotReceivingNotificationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
