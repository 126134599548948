import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gloria-tab-bar',
  templateUrl: './gloria-tab-bar.component.html',
  styleUrls: ['./gloria-tab-bar.component.scss'],
})
export class GloriaTabBarComponent implements OnInit {
  constructor() {}

  isCurrentUrl(url: string): boolean {
    return location.pathname.includes(url);
  }

  ngOnInit(): void {}
}
