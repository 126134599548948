import { Observable } from 'rxjs';
import { PageRepositoryService } from './abstract-repository.service';

/**
 * 예를 들어 같은 bbs api 에 조건을 달리해서 요청해야 하는 경우 사용
 *
 */
export class ExtPageRepositoryService<T> extends PageRepositoryService<T> {
  /**
   * 고정적으로 다르게 보내야 할 조건
   * */
  extFixedParams = {};

  create(data: T): Observable<T> {
    return super.create(Object.assign(data, this.extFixedParams));
  }

  update(id: any, item: T): Observable<T> {
    return super.update(id, Object.assign(item, this.extFixedParams));
  }

  appendPage(query = this.recentSearchQuery): void {
    return super.appendPage(Object.assign(query, this.extFixedParams));
  }

  getPage(query = this.recentSearchQuery): void {
    return super.getPage(Object.assign(query, this.extFixedParams));
  }

  getOptions(forceRefresh = true, params = {}): void {
    return super.getOptions(
      forceRefresh,
      Object.assign(params, this.extFixedParams)
    );
  }
}
