import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Utils } from '../core/utils';

const GA_TRACKING_ID = 'G-SDLZ9J78NG';

declare const gtag: any;

// TODO: index.html의 스크립트 대체
// TODO: gtag 타입 추가 https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/README.ko.md
/**
 * 구글 애널리틱스 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  /**
   * 구글 애널리틱스 서비스 초기화
   */
  init(): void {
    this.insertGtagConfigScript();
  }

  /**
   * 구매 이벤트
   *
   * @see https://developers.google.com/analytics/devguides/collection/ga4/set-up-ecommerce
   */
  booking(
    bookingId: number,
    value: number,
    mrhstId: number,
    mrhstNm: string
  ): void {
    gtag('event', 'purchase', {
      transaction_id: bookingId,
      value,
      items: [
        {
          item_id: mrhstId,
          item_name: mrhstNm,
          price: value,
        },
      ],
    });
    // gtag('event', 'purchase', {
    //   transaction_id: 'T_12345_1',
    //   value: 25.42,
    //   tax: 4.9,
    //   shipping: 5.99,
    //   currency: 'USD',
    //   coupon: 'SUMMER_SALE',
    //   items: [
    //     // If someone purchases more than one item,
    //     // you can add those items to the items array
    //     {
    //       item_id: 'SKU_12345',
    //       item_name: 'Stan and Friends Tee',
    //       affiliation: 'Google Merchandise Store',
    //       coupon: 'SUMMER_FUN',
    //       discount: 2.22,
    //       index: 0,
    //       item_brand: 'Google',
    //       item_category: 'Apparel',
    //       item_category2: 'Adult',
    //       item_category3: 'Shirts',
    //       item_category4: 'Crew',
    //       item_category5: 'Short sleeve',
    //       item_list_id: 'related_products',
    //       item_list_name: 'Related Products',
    //       item_variant: 'green',
    //       location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
    //       price: 9.99,
    //       quantity: 1,
    //     },
    //   ],
    // });
  }

  /**
   * 예약 취소
   */
  cancelBooking(bookingId: number): void {
    gtag('event', 'refund', {
      transaction_id: bookingId,
    });
  }

  /**
   * 스크립트 삽입
   */
  private insertGtagConfigScript(): void {
    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    const gtagScript: HTMLScriptElement = document.createElement('script');
    const configScript: HTMLScriptElement = document.createElement('script');
    // gtag 스크립트 경로
    const src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    // gtag 설정
    const config = {
      // debug_mode: false 입력해도 작동하지 않음
      ...(environment.name !== 'prod' && { debug_mode: true }),
    };
    // gtag 설정 json
    const configJson = Utils.getStringifiedJson(config, '{}');
    // config script text
    const text = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${GA_TRACKING_ID}', ${configJson});
    `;

    gtagScript.setAttribute('src', src);
    configScript.text = text;
    head.appendChild(gtagScript);
    head.appendChild(configScript);
  }
}
