import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GloriaContentSectionComponent } from './gloria-content-section.component';

@NgModule({
  declarations: [GloriaContentSectionComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [GloriaContentSectionComponent],
})
export class GloriaContentSectionModule {}
