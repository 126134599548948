import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserInfoService } from 'src/app/repository/user-info/user-info.service';
import { FindPwResetFormComponent } from './find-pw-reset-form/find-pw-reset-form.component';

@Component({
  selector: 'app-find-pw',
  templateUrl: './find-pw.component.html',
  styleUrls: ['./find-pw.component.scss'],
})
export class FindPwComponent implements OnInit {
  userInfoId: number;

  constructor(
    private matDialog: MatDialog,
    private matDialogRef: MatDialogRef<FindPwComponent>,
    private formBuilder: FormBuilder,
    private userInfoService: UserInfoService
  ) {}

  formGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  ngOnInit(): void {}

  onCancelClick(): void {
    this.matDialogRef.close();
  }

  onEmailCheckClick(): void {
    if (this.formGroup.valid)
      this.userInfoService
        .emailCheck(this.formGroup.value.email)
        .subscribe((res) => {
          this.userInfoId = res.userInfoIdList[0]
            ? res.userInfoIdList[0]
            : null;
        });
  }

  onClickPwResetBtn(id: number): void {
    this.matDialog
      .open(FindPwResetFormComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        panelClass: 'modal-no-padding',
        data: {
          userInfoId: id,
        },
      })
      .beforeClosed()
      .subscribe(() => this.matDialogRef.close());
  }
}
