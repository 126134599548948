import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'tmLocale',
})
export class TmLocalePipe implements PipeTransform {
  constructor(private lang: LanguageService) {}

  transform(
    value: any,
    key?: string,
    lang = this.lang.currentLocale.value
  ): string {
    if (!value || !value[key]) {
      return '';
    }

    return value[key];

    // FIXME: 구글 번역기 이용하기 위해 주석처리
    // if (typeof value === 'string') {
    //   return value;
    // }

    // // Cn
    // // En
    // // Kr
    // // Tw

    // let suffixKey: string;

    // if (lang === 'ja') {
    //   suffixKey = '';
    // } else if (lang === 'ko') {
    //   suffixKey = 'kr';
    // } else {
    //   suffixKey = lang;
    // }

    // const selectedKey = `${key}${_.startCase(suffixKey)}`;
    // const krKey = `${key}Kr`;
    // const enKey = `${key}En`;
    // const cnKey = `${key}Cn`;
    // const twKey = `${key}Tw`;
    // const selected =
    //   this.getValue(value[selectedKey]) ||
    //   this.getValue(value[key]) ||
    //   this.getValue(value[krKey]) ||
    //   this.getValue(value[enKey]) ||
    //   this.getValue(value[cnKey]) ||
    //   this.getValue(value[twKey]);

    // return selected || '';
  }

  private getValue(value: any): any {
    if (!value) {
      return null;
    }

    if (value === 'null') {
      return null;
    }

    return value;
  }
}
