<div id="pwChangeDialog">
  <div class="dialog-header">
    <span class="dialog-header-title">{{ 'findPw' | translate }}</span>
    <button mat-icon-button class="close-button" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dialog-body">
    <p class="dialog-comment">
      {{ 'safeNewPw' | translate }}
    </p>

    <form class="input-box" [formGroup]="formGroup">
      <div fxLayout="column" fxLayoutGap="15px">
        <div>
          <div>
            <app-input-wrapper
              [inputTitle]="'pw' | translate"
              [requiredFl]="true"
              [inputFormControls]="[formGroup.controls.pw]"
              [errorText]="'VALID.pw' | translate"
            >
              <input
                type="password"
                fxFlex="1 1 100%"
                id="pw"
                class="input"
                formControlName="pw"
                [placeholder]="'newPw' | translate"
              />
            </app-input-wrapper>
          </div>
          <!-- <div class="error-text">
            <p class="text">
              8~16자 영어/숫자/특수문자 중 2가지이상 조합해주세요.
            </p>
          </div> -->
        </div>

        <div>
          <div>
            <app-input-wrapper
              [inputTitle]="'checkPw' | translate"
              [requiredFl]="true"
              [inputFormControls]="[formGroup.controls.confirmPw]"
              [errorText]="'VALID.confirmPw' | translate"
            >
              <input
                type="password"
                fxFlex="1 1 100%"
                id="confirmPw"
                class="input"
                formControlName="confirmPw"
                [placeholder]="'checkPw' | translate"
              />
            </app-input-wrapper>
          </div>
          <!-- <div class="error-text">
            <p class="text">입력하신 비밀번호와 일치하지 않습니다.</p>
          </div> -->
        </div>
      </div>
    </form>

    <button (click)="onChangeClick()" id="pwResetBtn">
      {{ 'BTN.reset' | translate }}
    </button>
  </div>
</div>
