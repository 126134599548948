<mat-drawer-container hasBackdrop="true">
  <mat-drawer #sideMenu mode="over" position="end">
    <app-drawer [sideMenu]="sideMenu" fixedInViewport="true"></app-drawer>
  </mat-drawer>
  <app-gloria-header [sideMenu]="sideMenu"></app-gloria-header>

  <app-gloria-menu></app-gloria-menu>

  <router-outlet></router-outlet>

  <app-gloria-footer></app-gloria-footer>

  <app-gloria-tab-bar></app-gloria-tab-bar>
</mat-drawer-container>
