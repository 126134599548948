<div class="confirm" ngClass.lt-sm="mobile mobile-popup">
  <div class="close-wrap">
    <button mat-icon-button class="close-button" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="confirm-header-wrapper">
    <div class="confirm-header">{{ 'newReservation' | translate }}</div>
    <div class="confirm-header-subTitle">
      {{ 'choiceOfBookingMethod' | translate }}
    </div>
  </div>

  <div class="confirm-content-wrapper">
    <div class="confirm-content">
      <div class="confirm-content-header">
        {{ 'alreadyMember' | translate }}
      </div>
      <div class="confirm-content-card">
        <div class="confirm-content-title">
          {{ 'pleaseEnterEmailPw' | translate }}
        </div>
        <div class="confirm-content-input-group" [formGroup]="loginFrm">
          <app-input-wrapper
            [inputTitle]="'emailAsId' | translate"
            [requiredFl]="true"
            [inputFormControls]="[loginFrm.controls.username]"
          >
            <input
              id="username"
              class="font-body"
              fxFlex="1 1 0"
              type="username"
              formControlName="username"
              placeholder="{{ 'email' | translate }}"
              (keyup.enter)="onUsernameKeyupEnter()"
            />
          </app-input-wrapper>
          <app-input-wrapper
            [inputTitle]="'pw' | translate"
            [requiredFl]="true"
            [inputFormControls]="[loginFrm.controls.password]"
          >
            <input
              #passwordInput
              id="password"
              class="font-body"
              type="password"
              fxFlex="1 1 0"
              formControlName="password"
              (keyup.enter)="onPasswordKeyupEnter()"
              placeholder="{{ 'inputPw' | translate }}"
            />
          </app-input-wrapper>
        </div>
        <div class="confirm-content-option" (click)="onFindPwClick()">
          {{ 'clickHereIfYouForgotYourPassword' | translate }}
        </div>
        <div class="confirm-content-button-wrapper">
          <div class="confirm-content-button" (click)="onLoginClick()">
            {{ 'loginToBook' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="confirm-content">
      <div class="confirm-content-header">
        {{ 'notYetMember' | translate }}
      </div>
      <div class="confirm-content-card">
        <div class="confirm-content-title">
          {{ 'registerAsMemberToMakeReservation' | translate }}
        </div>
        <div
          class="confirm-content-description"
          [innerHTML]="'joinDescription' | translate"
        ></div>
        <div class="confirm-content-button-wrapper">
          <div class="confirm-content-button" (click)="onJoinClick()">
            {{ 'registerAsMemberToMakeReservation' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
