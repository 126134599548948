import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { TopFacilityService } from 'src/app/repository/hotel/top-facility.service';
import { TopRyokanService } from 'src/app/repository/hotel/top-ryokan.service';
import { IMrhst } from 'src/app/repository/mrhst/mrhst.model';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-gloria-footer',
  templateUrl: './gloria-footer.component.html',
  styleUrls: ['./gloria-footer.component.scss'],
})
export class GloriaFooterComponent implements OnInit {
  constructor(
    private topRyokanService: TopRyokanService,
    private topFacilityService: TopFacilityService,
    private languageService: LanguageService,
    private router: Router
  ) {}

  topRyokanList: IMrhst[];

  topFacilityList: IMrhst[];

  ngOnInit(): void {
    this.getTop1();
    this.getTop2();
  }

  get lang(): string {
    return this.languageService.currentLocale.value;
  }

  onClick(data: IMrhst): void {
    if (data.externalLink === '/') {
      return;
    }
    if (data.externalLink) {
      window.location.href = data.externalLink;
      return;
    }
    const currentUrl = window.location.href;

    // URL을 "/"로 분리하여 배열로 저장
    const urlParts = currentUrl.split('/');

    // 프로토콜, 호스트 이름, 포트 번호를 포함한 기본 주소 추출
    const baseUrl = `${urlParts[0]}//${urlParts[2]}/`;
    window.location.href = `${baseUrl}facility/${data.id}`;

    // this.router.navigate(['facility', data.id]);
  }

  getTop1(): void {
    this.topRyokanService.list$
      .pipe(
        tap(({ content }) => {
          this.topRyokanList = content;
        })
      )
      .subscribe();

    this.topRyokanService.getPage();
  }

  getTop2(): void {
    this.topFacilityService.list$
      .pipe(
        tap(({ content }) => {
          this.topFacilityList = content;
        })
      )
      .subscribe();

    this.topFacilityService.getPage();
  }
}
