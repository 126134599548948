import { AbstractHttpService } from 'pw-lib';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Page } from '../models/page';

export interface PageRequest {
  size: number;
  page: number;
  sort?: string[];
}

/**
 * api 서비스 구현에 필요한 추상클래스
 *
 * T : angular 프로젝트 내에서 사용하는 entity 형식
 *
 * SP : api 서버에서 전달받는 페이지 형식
 *
 * 사용법 :
 *
 * 1. 상속받아서 사용하며 SP 형식을 Page<T> 형식으로 바꾸기 위한 메소드(parsePage)를 구현해야 한다
 *
 * 여러 api 에서 사용한다면(= 한 서버의 mrhst, user 등이 같은 형식이라면) AbstractPageRepositoryService -> "CommonPageRepositoryService" -> EntityService 와 같은 방식으로 중간 서비스를 생성한 후 parsePage 등을 공통화 한다
 */
export abstract class AbstractRepositoryService<T = any, SP = any> {
  /**
   * 서버 URL
   * @example `environment.serverUrl`
   */
  protected serverUrl = environment.apiServerUrl;

  /**
   * 엔드포인트
   * @example 'oauth/token'
   */
  protected abstract baseUri: string;

  /**
   * 기본 정렬 조건
   * @example ['first,asc', 'second,desc']
   */
  protected abstract defaultSort: string[];

  constructor(protected http: AbstractHttpService) {}

  /**
   * id(=식별자)를 알 때 해당 데이터 요청
   */
  getItem(id: number): Observable<any> {
    return this.http
      .get(`${this.serverUrl}/${this.baseUri}/${id}`)
      .pipe(shareReplay());
  }

  /**
   * Page 요청
   */
  getPage(body: any = {}, params: any = {}): Observable<Page<T>> {
    return this.http
      .get(`${this.serverUrl}/${this.baseUri}`, {
        body,
        params: {
          ...params,
          ...this._getSort(params.sort),
        },
      })
      .pipe(
        map((res) => this.parsePage(res)),
        shareReplay()
      );
  }

  /**
   * Page 가져오는 api 사용해서 전체 목록 가져올 때 사용. 혹시 모를 부하를 방지하기 위해 기본 아이템 수는 1000개로 지정
   */
  getList(body: any = {}, params: any = {}): Observable<any[]> {
    return this.http
      .get(`${this.serverUrl}/${this.baseUri}`, {
        body,
        params: {
          ...params,
          ...this._getSort(params.sort),
          size: params.size || 1000,
        },
      })
      .pipe(
        map((response: T) => {
          return this.getListContent(response);
        }),
        shareReplay()
      );
  }

  /**
   * 입력 요청
   */
  post(body: any = {}, params: any = {}): Observable<T> {
    return this.http.post(`${this.serverUrl}/${this.baseUri}`, {
      body,
      params,
    });
  }

  /**
   * 데이터 전체 수정 요청
   */
  put(id: number, body: any = {}, params: any = {}): Observable<any> {
    return this.http.put(`${this.serverUrl}/${this.baseUri}/${id}`, {
      body,
      params,
    });
  }

  /**
   * 데이터중 일부 수정 요청
   */
  patch(id: number, body: any = {}, params: any = {}): Observable<any> {
    return this.http.patch(`${this.serverUrl}/${this.baseUri}/${id}`, {
      body,
      params,
    });
  }

  /**
   * 삭제 요청
   */
  delete(id: number, body: any = {}, params: any = {}): Observable<any> {
    return this.http.delete(`${this.serverUrl}/${this.baseUri}/${id}`, {
      body,
      params,
    });
  }

  /**
   * 서버의 페이지 형식과 angular 프로젝트에서 사용하는 페이지 형식이 다르기 때문에, angular 프로젝트에서 사용하는 페이지 형태로 변환
   *
   * @param serverPage
   */
  abstract parsePage(serverPage: SP): Page<T>;

  /**
   * list 결과값 반환
   */
  protected getListContent(response: any): any[] {
    const { content, _embedded } = response;

    if (content) {
      return content;
    }

    if (_embedded) {
      return _embedded[Object.keys(_embedded)[0]] ?? [];
    }

    if (Array.isArray(response) && response.length > 0) {
      return response;
    }

    return [];
  }

  private _getSort(sort: string | string[]): { sort: string[] } {
    const concated: string[] = [].concat(this.defaultSort).concat(sort);
    const newSort = concated.filter((sort) => sort ?? false);

    return { sort: newSort };
  }
}
