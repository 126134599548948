<div
  class="toolbar-spacer"
  ngClass.lt-sm="mobile"
  [fxShow.lt-sm]="showHeader"
></div>
<div class="toolbar-wrapper" ngClass.lt-sm="mobile" [fxShow.lt-sm]="showHeader">
  <mat-toolbar color="accent" ngClass.lt-sm="mobile">
    <a class="img-btn" routerLink="/home" fxHide.lt-sm="true">
      <img
        class="company-logo"
        src="/assets/img/compay-logo-with-text.png"
        ngClass.lt-sm="mobile"
      />
    </a>

    <a
      class="img-btn"
      routerLink="/home"
      *ngIf="!headerTitle; else hasTitle"
      fxShow.lt-sm="true"
      fxHide="true"
    >
      <img
        class="company-logo"
        src="/assets/img/compay-logo-with-text.png"
        ngClass.lt-sm="mobile"
      />
    </a>
    <ng-template #hasTitle>
      <div
        class="header-title-wrapper"
        fxShow.lt-sm="true"
        fxHide="true"
        fxLayout
        fxLayoutAlign="flex-start center"
      >
        <img
          class="go-back-icon"
          role="button"
          matRipple
          (click)="onClickBack()"
          src="/assets/img/arrow-back-icon-white.png"
        />
        <span class="header-title"> {{ headerTitle | translate }}</span>
      </div>
    </ng-template>
    <div class="spacer"></div>

    <!-- 2024-07-24 부터 회원 관련 기능 중지 -->
    <!-- <a
      class="img-caption-btn"
      (click)="onMenuClick('/my/rsrv')"
      fxHide.lt-sm="true"
    >
      <img class="img" src="/assets/img/booking-icon.png" />
      <span class="caption">{{ 'rsrvHist' | translate }}</span>
    </a>
    <a
      class="img-caption-btn"
      (click)="onMenuClick('/my/fav')"
      fxHide.lt-sm="true"
    >
      <img class="img" src="/assets/img/favorite-icon.png" />
      <span class="caption">{{ 'fav' | translate }}</span>
    </a>
    <button
      mat-icon-button
      id="btnLogin"
      type="button"
      [matMenuTriggerFor]="loginMenu"
      fxHide.lt-sm="true"
    >
      <a class="img-caption-btn">
        <mat-icon id="lockIcon" class="on-icon" *ngIf="isLoggedIn"
          >lock</mat-icon
        >

        <mat-icon id="lockOpenIcon" class="off-icon" *ngIf="!isLoggedIn"
          >lock_open</mat-icon
        >
        <span class="caption">{{ 'auth' | translate }}</span>
      </a>
    </button> -->

    <select
      class="language-select"
      [(ngModel)]="lang"
      (ngModelChange)="onChangeLanguage($event)"
      fxHide.lt-sm="true"
    >
      <option class="notranslate" value="ja">日本語</option>
      <option class="notranslate" value="ko">한국어</option>
      <option class="notranslate" value="en">English</option>
      <option class="notranslate" value="zh-CN">中文(簡體)</option>
      <option class="notranslate" value="zh-TW">中文(繁體)</option>
    </select>
    <button
      class="side-menu"
      (click)="sideMenu.toggle()"
      fxHide
      fxShow.lt-sm="true"
      *ngIf="!headerTitle"
    >
      <img src="/assets/img/menu-icon.png" />
    </button>
  </mat-toolbar>
</div>
<mat-menu #loginMenu="matMenu" xPosition="before">
  <!-- 로그아웃(미로그인) 상태일 때 표시 -->
  <button mat-menu-item (click)="onClickLogin()" *ngIf="!isLoggedIn">
    {{ 'login' | translate }}
  </button>

  <!-- 2024-03-01 부터 신규 가입 중지 -->
  <!-- <button mat-menu-item routerLink="/join" *ngIf="!isLoggedIn">
    {{ 'join' | translate }}
  </button> -->

  <!-- 로그인 상태일 때 표시 -->
  <button mat-menu-item (click)="onClickChangeInfo()" *ngIf="isLoggedIn">
    {{ 'changeUserInfo' | translate }}
  </button>
  <button mat-menu-item (click)="onClickLogout()" *ngIf="isLoggedIn">
    {{ 'logout' | translate }}
  </button>
</mat-menu>
