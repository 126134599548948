import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, filter, tap } from 'rxjs';
import {
  GoogleTranslationService,
  Language,
} from 'src/app/core/google-translation.service';
import { UserInfo } from 'src/app/repository/user-info/user-info.model';
import { GloriaUserInfoService } from 'src/app/services/gloria-user-info.service';
import { LanguageService } from 'src/app/services/language.service';
import { AuthService } from '../../auth/auth.service';
import { DialogService } from '../dialog/dialog.service';
import { LoginComponent } from '../dialog/login/login.component';

@Component({
  selector: 'app-gloria-header',
  templateUrl: './gloria-header.component.html',
  styleUrls: ['./gloria-header.component.scss'],
})
export class GloriaHeaderComponent implements OnInit {
  lang: string;

  /** 로그인 여부 */
  isLoggedIn: boolean;

  @Input() sideMenu: any;

  #headerTitleList = [
    { key: '/notice', headerTitle: 'notice', showHeader: true },
    { key: '/qna', headerTitle: 'qnaPrivate', showHeader: true },
    // { key: '/mobile-my', headerTitle: 'myPage' },
    { key: '/change-info', headerTitle: 'changeUserInfo', showHeader: true },
    { key: '/my/rsrv/', headerTitle: 'rsrvDetail', showHeader: true },
    { key: '/facility/', headerTitle: '', showHeader: false },
  ];

  headerTitle = null;

  showHeader = true;

  userInfo$: Observable<UserInfo>;

  constructor(
    private authService: AuthService,
    private languageService: LanguageService,
    private userInfoService: GloriaUserInfoService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private location: Location,
    private router: Router,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private googleTranslationService: GoogleTranslationService
  ) {}

  ngOnInit(): void {
    this.userInfo$ = this.authService.loginSubject$.asObservable().pipe(
      tap(() => {
        this.isLoggedIn =
          this.authService.loginInfo != null &&
          this.authService.loginInfo?.certType !== 'GUEST';
      })
    );
    this.userInfo$.subscribe();
    this.setDefaultLang();

    this.setHeaderTitle(this.location.path());
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setHeaderTitle(event.url);
      }
    });
  }

  /**
   * 브라우저 언어 값 확인 후 초기 표시 언어 설정
   */
  setDefaultLang(): void {
    const defaultLang = this.languageService.currentLocale.value;
    this.lang = defaultLang;
  }

  private setHeaderTitle(url: string): void {
    let headerTitle;
    let showHeader = true;
    this.#headerTitleList.some((v) => {
      if (url.includes(v.key)) {
        headerTitle = v.headerTitle;
        showHeader = v.showHeader;
        return true;
      }
      return false;
    });
    this.showHeader = showHeader;
    this.headerTitle = headerTitle;
  }

  onChangeLanguage(lang: string): void {
    this.googleTranslationService.changeLanguage(lang as Language);
    this.languageService.changeLang(lang);
  }

  /**
   * 로그인 버튼 클릭 시 로그인 상태에 따라 로그인페이지로 이동하거나, 로그아웃 처리
   */
  onClickLogin(): void {
    if (this.isLoggedIn) {
      this.authService.logout();
      window.location.href = '/';
    } else {
      this.matDialog
        .open(LoginComponent, {
          maxWidth: '100vw',
        })
        .beforeClosed()
        .pipe(
          filter((isLogin) => isLogin),
          tap(() => {
            this.isLoggedIn = true;
          })
        )
        .subscribe();
    }
  }

  /**
   * 로그아웃 버튼 클릭 시 무조건 로그아웃 처리. login과 동작이 다른 이유는 logout 버튼을 어떻게 표시할지 정책에 따라 달라질 수 있으므로
   *
   * ex> 다른 요소로서 로그아웃 버튼을 만들면 logout 메소드를 바인딩하고, 같은 요소를 활용하면 상태값에 따라 login 메소드로 구분 동작
   */
  onClickLogout(): void {
    this.authService.logout();
    window.location.href = '/';
  }

  /**
   * 회원정보 변경 버튼 클릭했을 때
   *
   * 회원 정보 페이지로 이동
   */
  onClickChangeInfo(): void {
    this.router.navigate(['/my/change-info']);
  }

  /**
   * 뒤로가기 버튼 (모바일 제목이 있는경우 표시) 클릭시
   *
   * 이전 페이지로 이동
   */
  onClickBack(): void {
    if (this.route.snapshot.queryParams?.prevPath) {
      const queryParams = this.route.snapshot.queryParams?.prevQueryParams
        ? JSON.parse(this.route.snapshot.queryParams.prevQueryParams)
        : null;
      this.router.navigate([this.route.snapshot.queryParams?.prevPath], {
        queryParams,
      });
    } else this.location.back();
  }

  onClickRefreshInfo(): void {
    this.authService.refreshLoginInfo$().subscribe();
  }

  onMenuClick(path: string): void {
    this.router.navigateByUrl(path);
  }
}
