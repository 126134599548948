import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { DialogService } from '../components/dialog/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class ZipCodeService {
  baseUri = `https://api.zipaddress.net/`;

  constructor(
    protected http: HttpClient,
    private dialogService: DialogService
  ) {}

  getAddres(zipCode: string): Observable<any> {
    return this.http.get(`${this.baseUri}?zipcode=${zipCode}`).pipe(
      map(({ data, code }: any) => {
        if (code >= 200 && code < 300) {
          const prefectures = data.pref;
          const municipality = data.address;
          return { prefectures, municipality, status: 'success' };
        }
        return { status: 'fail' };
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const { status } = httpErrorResponse;

        // TODO: 공통 처리 필요
        if (status === 0) {
          // 네트워크 연결되어있지 않거나 타임아웃일때 메시지 표시
          this.dialogService
            .alert('networkConnectionError', 'alert')
            .subscribe();
        }

        return throwError(() => httpErrorResponse);
      })
    );
  }
}
