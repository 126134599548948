import * as _ from 'lodash-es';

import { Pipe, PipeTransform } from '@angular/core';
import { PwLocalePipe } from 'projects/pw-lib/src/public-api';
import { LanguageService } from '../services/language.service';

/**
 * @deprecated data-i18n.pipe 사용
 */
@Pipe({
  name: 'locale',
})
export class LocalePipe implements PipeTransform {
  constructor(private lang: LanguageService) {}

  transform(
    value: any,
    key?: string,
    lang = this.lang.currentLocale.value
  ): string {
    if (!value) {
      return '';
    }

    if (typeof value === 'string') {
      return new PwLocalePipe().transform(value, lang);
    }

    const suffixKey = lang === 'ko' ? '' : lang;
    const selectedKey = `${key}${_.startCase(suffixKey)}`;
    const jaKey = `${key}Ja`;
    const enKey = `${key}En`;
    const selected =
      this.getValue(value[selectedKey]) ||
      this.getValue(value[jaKey]) ||
      this.getValue(value[key]) ||
      this.getValue(value[enKey]);

    return selected || '';
  }

  private getValue(value: any): any {
    if (!value) {
      return null;
    }

    if (value === 'null') {
      return null;
    }

    return value;
  }
}
