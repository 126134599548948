import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import { IUserInfo, userInfoEqualsFields } from './user-info.model';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService extends PageRepositoryService<IUserInfo> {
  baseUri = `api/userInfo`;

  equalsFields: string[] = userInfoEqualsFields;

  /* extFixedParams */
  /* /extFixedParams */

  constructor(protected http: HttpClient) {
    super(http);
  }

  // FIXME
  /**
   * 이메일 등록 여부 확인
   */
  emailCheck(email: string): Observable<any> {
    return this.http.get(`${this.apiServerUrl}/${this.baseUri}/checkExist`, {
      params: { userId: email },
    });
  }

  /**
   * 비밀번호 변경
   */
  changePw(id: number, userPw: string): Observable<any> {
    return this.http.put(
      `${this.apiServerUrl}/${this.baseUri}/updatePw/${id}`,
      {
        userPw,
      }
    );
  }
}
