import {
  HttpContext,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractAuthService } from 'pw-lib';
import {
  Observable,
  catchError,
  finalize,
  mergeMap,
  of,
  retry,
  shareReplay,
  throwError,
} from 'rxjs';

type HttpUpdate = {
  headers?: HttpHeaders;
  context?: HttpContext;
  reportProgress?: boolean;
  params?: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
  body?: any | null;
  method?: string;
  url?: string;
  setHeaders?: {
    [name: string]: string | string[];
  };
  setParams?: {
    [param: string]: string;
  };
};

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private httpRequestUpdate: (token: any) => HttpUpdate = (token) => {
    return {
      setHeaders: {
        Authorization: `${token.token_type} ${token.access_token}`,
      },
    };
  };

  private authRequest$: Observable<any>;

  constructor(
    private authService: AbstractAuthService,
    private includedList: string[],
    private excludedList?: string[],
    injectedHttpRequestUpdate?: (token: any) => HttpUpdate
  ) {
    this.httpRequestUpdate =
      injectedHttpRequestUpdate || this.httpRequestUpdate;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // 토근 전송 할 주소가 맞으면 true
    const hasIncluded = this.includedList?.some(
      (included) => request.url.indexOf(included) >= 0
    );
    // 토큰 전송하지 말아야 할 주소이면 true
    const hasExcluded = this.excludedList?.some(
      (excluded) => request.url.indexOf(excluded) >= 0
    );

    // 토큰 전송 할 주소가 아니거나 보내지 말아야 할 주소이면
    if (!hasIncluded || hasExcluded) {
      // 토큰 없이 요청
      return next.handle(request);
    }

    const auth$ = this.authService.auth
      ? of(this.authService.auth)
      : this.authService.getNewAuth();

    return auth$.pipe(
      mergeMap((token) => {
        return (
          token
            ? this.getRequestWithToken(request, next, token)
            : next.handle(request)
        ).pipe(
          retry({
            count: 1,
            delay: (e) => {
              if (e.status >= 400 && e.status <= 499) {
                return token
                  ? this.getRequestWithToken(request, next, token)
                  : next.handle(request);
              }
              return throwError(() => e);
            },
          })
        );
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse?.error?.error === 'invalid_token') {
          return this.getRefreshRequest(request, next);
        }

        return throwError(() => httpErrorResponse);
      }),
      shareReplay()
    );
  }

  private getRequestWithToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: any
  ): Observable<HttpEvent<any>> {
    const authAddedRequest: HttpRequest<any> = request.clone(
      this.httpRequestUpdate(token)
    );

    return next.handle(authAddedRequest);
  }

  private getRefreshRequest(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // 기존 재인증 요청이 있다면 그대로 사용, 아니면 요청 생성
    this.authRequest$ =
      this.authRequest$ ||
      this.authService.getRefreshAuth().pipe(shareReplay());
    return this.authRequest$.pipe(
      mergeMap((token) => {
        return this.getRequestWithToken(request, next, token);
      }),
      catchError((refreshError: HttpErrorResponse) => {
        if (refreshError?.error?.error === 'invalid_token') {
          this.authService.clearAuth();
        }

        return throwError(() => refreshError);
      }),
      finalize(() => {
        this.authRequest$ = null;
      }),
      shareReplay()
    );
  }
}
