import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { GloriaTabBarComponent } from './gloria-tab-bar.component';

@NgModule({
  declarations: [GloriaTabBarComponent],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [GloriaTabBarComponent],
})
export class GloriaTabBarModule {}
