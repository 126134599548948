import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  /**
   * 현재 선택한 언어
   */
  #lang: string;

  /**
   * 현재 선택한 언어가 없다면 스토리지 > 브라우저 기본 값 중 표시 언어, en 순으로 확인하며 가져온다
   */
  get lang(): string {
    if (this.#lang) {
      return this.#lang;
    }

    /**
     * 스토리지 설정 언어값이 없다면, 브라우저 설정값, 이것도 못 읽으면 en을 기본으로 설정
     * navigator.language 는 없는 경우가 없을 것 같고. ko, ko-KR 두 가지 형태로 줄 수 있으므로 split 하여 사용
     */
    // const navigatorLanguage = navigator.language
    //   ? navigator.language.split('-')[0]
    //   : 'en';
    /**
     * 브라우저 설정값에서 기본 일본어로 변경
     */
    const navigatorLanguage = 'ja';
    this.#lang = localStorage.getItem('lang') ?? navigatorLanguage;
    if (!this.SUPPORTED_LANGUAGES.find((item) => item.value === this.#lang)) {
      // 지원언어에 선택한 언어가 없다면 'en' 선택
      this.#lang = 'en';
    }
    this.document.documentElement.lang = this.#lang;
    this.translateService.use(this.#lang);
    return this.#lang;
  }

  /**
   * 현재 지원하는 언어
   */
  SUPPORTED_LANGUAGES = [
    { name: '日本語', value: 'ja' },
    { name: '한국어', value: 'ko' },
    { name: 'English', value: 'en' },
    { name: '中文(簡體)', value: 'zh-CN' },
    { name: '中文(繁體)', value: 'zh-TW' },
  ];

  constructor(
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  /** 표시 언어 상태를 변경하고 스토리지에 저장 */
  changeLang(lang: string): void {
    this.#lang = lang;
    this.translateService.use(lang);
    this.document.documentElement.lang = lang;
    localStorage.setItem('lang', lang);
    // window.location.reload();
  }

  get currentLocale(): { name: string; value: string } {
    return this.SUPPORTED_LANGUAGES.find((item) => item.value === this.lang);
  }
}
