import { Type } from '../enumerations/type.model';

/* extFixedValues */
/* /extFixedValues */

/** 두 객체가 같은지 확인할 때 사용할 필드 목록 */
/* model_equalsFields */ export const certEqualsFields = ['id'];
/* /model_equalsFields */

/**
 * cert 인터페이스
 */
export interface ICert {
  /**
   * id
   */
  id?: number;
  /**
   * sj
   */
  title?: string;
  /**
   * cn
   */
  content?: string;
  /**
   * del Yn
   */
  isDelete?: any;
  /**
   * reg dttm
   */
  createDate?: Date;
  /**
   * type
   */
  type?: Type;
}

/**
 * cert 클래스. 기본값 포함한 객체 생성
 */
export class Cert implements ICert {
  constructor(
    public id?: number,
    public title?: string | null,
    public content?: string | null,
    public isDelete?: any | null,
    public createDate?: Date | null,
    public type?: Type
  ) {}
}

export interface CertRequestModel {
  brandCd: string;
  corpNo: string;
  reqType: 'CODE' | 'URL';
  receiver: string;
  certUrlHost?: string;
}

export interface CertResponse {
  result: boolean;
  certId?: number;
  valid?: number;
  receiver?: string;
}

export interface CertCheckModel {
  brandCd: string;
  corpNo: string;
  receiver: string;
  certId: number;
  certNum?: string;
  extendFl?: boolean;
}
