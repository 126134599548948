import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, tap, timeout } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DialogService } from '../dialog.service';
import { FindPwComponent } from '../find-pw/find-pw.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('pwForm') pwForm: ElementRef;

  loginForm = this.formBuilder.group({
    id: [null, Validators.required],
    pw: [null, Validators.required],
    // shouldSaveId: [null],
  });

  isPasswordVisible = false;

  constructor(
    private router: Router,
    private matDialogRef: MatDialogRef<LoginComponent>,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  onLoginClick(): void {
    if (this.loginForm.invalid) {
      this.dialogService
        .alert(this.translateService.instant('ALERT.Required_ID_PW'))
        .subscribe();
      return;
    }

    const { id, pw } = this.loginForm.value;

    this.authService
      .login(id, pw)
      .pipe(
        tap(() => {
          this.matDialogRef.close(true);
        }),
        // 10초 타임아웃
        timeout(10000),
        catchError((error: any) => {
          this.authService.clearAuth();

          // 타임아웃일때
          if (
            error?.name === 'TimeoutError' ||
            error?.status === 0 ||
            error?.status === 504
          ) {
            return this.dialogService.alert(
              this.translateService.instant('networkConnectionError'),
              'alert'
            );
          }

          return this.dialogService.alert(
            this.translateService.instant('ALERT.Login_Error'),
            'alert'
          );
        })
      )
      .subscribe();
  }

  onFindPwClick(): void {
    this.dialogService.matDialog.open(FindPwComponent, {
      maxWidth: '100vw',
    });
  }
}
