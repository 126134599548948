import { IKeycloakUser } from '../keycloak-user/keycloak-user.model';
import { IUserCard } from '../user-card/user-card.model';

/* extFixedValues */
/* /extFixedValues */

/** 두 객체가 같은지 확인할 때 사용할 필드 목록 */
/* model_equalsFields */ export const userInfoEqualsFields = ['username'];
/* /model_equalsFields */

/**
 * 회원 정보 인터페이스
 */
export interface IUserInfo {
  /**
   * id
   */
  id?: number;
  /**
   * userNm
   */
  userNm?: string;
  /**
   * lastname
   */
  familyName?: string;
  /**
   * firstname
   */
  firstName?: string;
  /**
   * lastname2
   */
  familyName2?: string;
  /**
   * firstname2
   */
  firstName2?: string;
  /**
   * email
   */
  username?: string;
  /**
   * main contact
   */
  mobileNum?: string;
  /**
   * sub contact
   */
  telNumber?: string;
  /**
   * userId
   */
  userId?: string;
  /**
   * email
   */
  email?: string;
  /**
   * age
   */
  age?: string;
  /**
   * gender
   */
  gender?: string;
  /**
   * address
   */
  address?: string;
  /**
   * keycloakUser
   */
  keycloakUser?: IKeycloakUser;
  /**
   * zip
   */
  zipcode?: string;
  /**
   * prefecture
   */
  addrCode?: string;
  /**
   * reg dttm
   */
  regDttm?: string;
  /**
   * cert type
   */
  certType?: string;
  /**
   * user_card
   */
  userCard?: IUserCard;
}

/**
 * 회원 정보 클래스. 기본값 포함한 객체 생성
 */
export class UserInfo implements IUserInfo {
  constructor(
    public id?: number,
    public userNm?: string | null,
    public familyName?: string | null,
    public firstName?: string | null,
    public familyName2?: string | null,
    public firstName2?: string | null,
    public username?: string | null,
    public mobileNum?: string | null,
    public email?: string | null,
    public telNumber?: string | null,
    public userId?: string | null,
    public age?: string | null,
    public gender?: string | null,
    public address?: string | null,
    public keycloakUser?: IKeycloakUser | null,
    public zipcode?: string | null,
    public addrCode?: string | null,
    public regDttm?: string | null,
    public certType?: string | null,
    public userCard?: IUserCard | null
  ) {}
}
