<div class="drawer">
  <div>
    <div class="btn-group" fxLayout="row" fxLayoutAlign="flex-end center">
      <select
        tabindex="-1"
        class="language-select"
        [pwCoachMark]="{ text: 'GUIDE.changeLang' | translate }"
        [(ngModel)]="lang"
        (ngModelChange)="onChangeLanguage($event)"
      >
        <option class="notranslate" value="ja">日本語</option>
        <option class="notranslate" value="ko">한국어</option>
        <option class="notranslate" value="en">English</option>
        <option class="notranslate" value="zh-CN">中文(簡體)</option>
        <option class="notranslate" value="zh-TW">中文(繁體)</option>
      </select>

      <!-- 2024-07-24 부터 회원 관련 기능 중지 -->
      <!-- <button
        mat-menu-item
        type="button"
        (click)="onClickLogout()"
        id="logout"
        *ngIf="isLoggedIn"
      >
        <img src="/assets/img/logout-icon.png" />
      </button> -->

      <button (click)="close()" id="close" type="button">
        <img src="/assets/img/close-button-icon-square.png" />
      </button>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      class="profile"
      *ngIf="isLoggedIn; else guest"
    >
      <ng-container *ngIf="userInfo$ | async as userInfo">
        <div>
          <p>{{ 'welcome' | translate }}</p>
          <p class="notranslate">
            <ng-container *ngIf="lang === 'ja' || lang === 'ko'; else en">
              {{ userInfo.familyName }}
              {{ userInfo.firstName }}
            </ng-container>
            <ng-template #en>
              {{ userInfo.familyName }}
              {{ userInfo.firstName }}
            </ng-template>
            {{ 'sir' | translate }}
          </p>
        </div>
      </ng-container>
    </div>
    <ng-template #guest>
      <div
        fxLayout="row"
        fxLayoutAlign="flex-start center"
        fxLayoutGap="20px"
        class="profile guest"
      >
        <!-- 2024-03-01 부터 신규 가입 중지 -->
        <!-- <button
          role="link"
          matRipple
          type="button"
          routerLink="/join"
          (click)="close()"
        >
          {{ 'join' | translate }}
        </button> -->

        <!-- 2024-07-24 부터 회원 관련 기능 중지 -->
        <!-- <button role="link" matRipple type="button" (click)="onClickLogin()">
          {{ 'login' | translate }}
        </button> -->
      </div>
    </ng-template>
    <div
      class="membership"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      (click)="close()"
      routerLink="/tabi"
      *ngIf="isLoggedIn"
    >
      <img src="/assets/img/info-icon-black.png" />
      <p>{{ 'membershipIntro' | translate }}</p>
    </div>

    <!-- 2024-07-24 부터 회원 관련 기능 중지 -->
    <!-- <div fxLayout="row" fxLayoutAlign="space-around center" class="my-menu">
      <a routerLink="/my/rsrv" (click)="close()" role="link" matRipple>{{
        'rsrvHist' | translate
      }}</a>
      <img class="divider-bar" src="/assets/img/divider-bar.svg" />
      <a routerLink="/my/fav" (click)="close()" role="link" matRipple>{{
        'fav' | translate
      }}</a>
    </div> -->

    <!-- <div
    class="cpn-wrapper"
    *ngIf="cpnList$ | async as cpnList"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    routerLink="/my/cpn"
    (click)="close()"
  >
    <span class="cpn-title">{{ 'cpn' | translate }}</span>
    <span class="cpn-cnt" *ngIf="isLoggedIn"
      >{{ cpnList.content.length ?? 0 | number }}
      {{ 'UNIT.cnt' | translate }}</span
    >
  </div> -->

    <div class="menu-list">
      <a
        class="menu-item notranslate"
        fxLayout="row"
        fxLayoutAlign="flex-start center"
        routerLink="/home"
        (click)="close()"
        role="link"
        matRipple
      >
        Home
      </a>
      <!-- <a
      class="menu-item"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/rsrv/facility"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'rsrv' | translate }}
    </a> -->
      <!-- <a
      class="menu-item"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/ordr"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'ordr' | translate }}
    </a> -->
      <!-- <a
      class="menu-item child"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/ordr/hist"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'delngHist' | translate }}
    </a> -->
      <a
        class="menu-item"
        fxLayout="row"
        fxLayoutAlign="flex-start center"
        routerLink="/facility"
        (click)="close()"
        role="link"
        matRipple
      >
        {{ 'facilityList' | translate }}
      </a>
      <a
        class="menu-item"
        fxLayout="row"
        fxLayoutAlign="flex-start center"
        routerLink="/video"
        (click)="close()"
        role="link"
        matRipple
      >
        {{ 'introMv' | translate }}
      </a>
      <!-- <a
      class="menu-item parent"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
    >
      {{ 'csCenter' | translate }}
    </a> -->
      <!-- <a
      class="menu-item child"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/cs/notice"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'notice' | translate }}
    </a> -->
      <!-- <a
      class="menu-item child"
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      routerLink="/cs/qna"
      (click)="close()"
      role="link"
      matRipple
    >
      {{ 'qnaPrivate' | translate }}
    </a> -->
      <a
        class="menu-item"
        fxLayout="row"
        fxLayoutAlign="flex-start center"
        routerLink="/company"
        (click)="close()"
        role="link"
        matRipple
      >
        {{ 'companyInfo' | translate }}
      </a>
      <a
        class="menu-item"
        fxLayout="row"
        fxLayoutAlign="flex-start center"
        routerLink="/tabi"
        (click)="close()"
        role="link"
        matRipple
      >
        {{ 'tabiClub' | translate }}
      </a>
      <a
        class="menu-item"
        fxLayout="row"
        fxLayoutAlign="flex-start center"
        routerLink="/recruit"
        (click)="close()"
        role="link"
        matRipple
      >
        {{ 'recruit' | translate }}
      </a>
    </div>
  </div>
  <div class="drawer-sns">
    <div class="sns-card">
      <div class="sns-title">SNS</div>
      <div class="sns-icon-warpper">
        <a
          class="img-caption-btn"
          href="https://www.instagram.com/new__gloria/"
          target="_blank"
        >
          <div class="instagram-icon">
            <img class="icon" src="/assets/img/instagram-icon.png" />
          </div>
        </a>
        <a
          class="img-caption-btn"
          href="https://liff.line.me/1645278921-kWRPP32q/?accountId=135ztycd"
          target="_blank"
        >
          <div class="line-icon">
            <img class="icon" src="/assets/img/line-icon.png" />
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
