<div id="findPw">
  <div class="close-wrap">
    <button mat-icon-button class="close-button" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="content">
    <span class="title">{{ 'findPw' | translate }}</span>

    <!-- TODO: 언어마다 줄바꿈이 다른점, html태그가 필요한점 등 자체 번역 검토. 지오씨쪽에서 관련 규칙에 대한 검토 필요 -->
    <div class="email-guide">
      ご登録するメールアドレスを入力のうえ、
      <br />
      メール認証をクリックしてください。
      <br />
      <br />
      ご入力いただいたメールアドレスに
      <br />
      6桁の認証番号が届きますので、認証番号の欄にご入力をお願いいたします。
      <br />
    </div>

    <app-email-check
      [joinFrm]="formGroup"
      [mode]="'dialog'"
      (onClickNext)="onClickPwResetBtn($event)"
      (onClickCancel)="onCancelClick()"
      [isNeedSame]="true"
    >
    </app-email-check>

    <!-- <form class="input-box" [formGroup]="formGroup">
      <div class="input-form">
        <div>
          <app-input-wrapper
            wrapper
            [inputTitle]="'email' | translate"
            [requiredFl]="true"
            [inputFormControls]="[formGroup.controls.email]"
            [errorText]="'VALID.email' | translate"
          >
            <div class="input-warpper">
              <input
                id="email"
                formControlName="email"
                placeholder="{{ 'emailPlaceholder' | translate }}"
              />
              <button (click)="onEmailCheckClick()" class="btn-outline-blue">
                {{ 'validateEmail' | translate }}
              </button>
            </div>
          </app-input-wrapper>
        </div>
        <div *ngIf="userInfoId" class="success-text">
          <p class="text">
            {{ 'VALID.checkEmail' | translate }}
          </p>
        </div>
        <div *ngIf="userInfoId === null && formGroup.valid" class="error-text">
          <p class="text">
            {{ 'VALID.errorEmail' | translate }}
          </p>
        </div>
        </div>
        <div>
          <span class="input-nm">{{ 'certNum' | translate }}</span>
          <div fxLayout="row" fxLayoutGap="9px">
            <input
              fxFlex="1 1 100%"
              id="certifyNum"
              placeholder="{{ 'inputCertNumNDigit' | translate: { n: 4 } }}"
            />
            <button class="btn-outline-blue">{{ 'check' | translate }}</button>
          </div>
          <div fxLayout="row" fxLayoutAlign="flex-end center">
            <span id="reSend">{{ 'resend' | translate }}</span>
          </div>
          <div class="error-text">
            <p class="text">{{ 'VALID.cert' | translate }}</p>
          </div>
      </div>
    </form> -->

    <!-- <button id="pwResetBtn" type="button" (click)="onClickPwResetBtn()">
      {{ 'change' | translate }}
    </button> -->
  </div>
</div>
