import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, shareReplay, Subject } from 'rxjs';

export interface ScrollEvent {
  scrollTop?: number;
  scrollLeft?: number;
  scrollHeight?: number;
  scrollWidth?: number;
}

/**
 * UI 제어를 위한 클래스
 */
@Injectable({
  providedIn: 'root',
})
export class UiService {
  /**
   * app-root의 스크롤 이벤트 서브젝트
   */
  scrollEvent$: BehaviorSubject<ScrollEvent> = new BehaviorSubject({});

  /** 모바일 구분해서 표시할 때 사용 */
  isMobile$: Observable<boolean>;

  mobileMunuOpen$: Subject<void> = new Subject();

  constructor(private _breakpointObserver: BreakpointObserver) {
    // 모바일 기기 기준
    this.isMobile$ = this._breakpointObserver
      .observe(['(max-width: 600px)'])
      .pipe(
        map((r: any) => {
          return r.matches;
        }),
        shareReplay()
      );
  }
}
