import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DialogService } from '../dialog.service';
import { FindPwComponent } from '../find-pw/find-pw.component';

@Component({
  selector: 'app-confirm-login',
  templateUrl: './confirm-login.component.html',
  styleUrls: ['./confirm-login.component.scss'],
})
export class ConfirmLoginComponent implements OnInit {
  loginFrm: FormGroup = this._formBuilder.group({
    username: [null, Validators.required],
    password: [null, Validators.required],
  });

  /**
   * 비밀번호 입력란
   */
  @ViewChild('passwordInput') passwordInput!: ElementRef<HTMLInputElement>;

  constructor(
    private _formBuilder: FormBuilder,
    private dialogService: DialogService,
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private matDialogRef: MatDialogRef<ConfirmLoginComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { nextPath?: string[]; nextQuery?: Params }
  ) {}

  ngOnInit(): void {}

  /**
   * 아이디 입력란에서 엔터시
   */
  onUsernameKeyupEnter(): void {
    this.passwordInput.nativeElement.focus();
  }

  /**
   * 패스워드 입력란에서 엔터시
   */

  onPasswordKeyupEnter(): void {
    this.onLoginClick();
  }

  /**
   * 로그인 클릭시
   */
  onLoginClick(): void {
    if (this.loginFrm.invalid) {
      this.dialogService
        .alert(this.translateService.instant('ALERT.Required_ID_PW'))
        .subscribe();

      return;
    }

    const { username, password } = this.loginFrm.value;

    this.authService
      .login(username, password)
      .pipe(
        tap(() => {
          this.matDialogRef.close(true);
        }),
        catchError(() => {
          return this.dialogService.alert(
            this.translateService.instant('ALERT.Login_Error')
          );
        })
      )
      .subscribe();
  }

  /**
   * 비밀번호 찾기 클릭시
   */
  onFindPwClick(): void {
    this.dialogService.matDialog.open(FindPwComponent, {
      maxWidth: '100vw',
    });
  }

  /**
   * 회원가입 클릭시
   */
  onJoinClick(): void {
    this.matDialogRef.close();
    this.router.navigate(['join']);
  }
}
