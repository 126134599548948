import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EmailCheckModule } from '../email-check/email-check.module';
import { InputWrapperModule } from '../input-wrapper/input-wrapper.module';
import { SharedModule } from '../shared/shared.module';
import { AlertComponent } from './alert/alert.component';
import { ConfirmLoginComponent } from './confirm-login/confirm-login.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { EmailNotReceivingNotificationComponent } from './email-not-receiving-notification/email-not-receiving-notification.component';
import { FindPwResetFormComponent } from './find-pw/find-pw-reset-form/find-pw-reset-form.component';
import { FindPwComponent } from './find-pw/find-pw.component';
import { LoginComponent } from './login/login.component';
import { SearchZipCodeComponent } from './search-zip-code/search-zip-code.component';
import { TmConfirmBookingComponent } from './tm-confirm-booking/tm-confirm-booking.component';

const componentList = [
  AlertComponent,
  ConfirmLoginComponent,
  TmConfirmBookingComponent,
  LoginComponent,
  FindPwComponent,
  FindPwResetFormComponent,
  ConfirmComponent,
  SearchZipCodeComponent,
  EmailNotReceivingNotificationComponent,
];

@NgModule({
  declarations: componentList,
  imports: [
    CommonModule,
    MatDialogModule,
    SharedModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    InputWrapperModule,
    EmailCheckModule,
  ],
  exports: [...componentList, MatDialogModule],
})
export class DialogModule {}
