import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { InputCheckDirective } from './input-check.directive';
import { TextTemplateViewerDirective } from './text-template-viewer.directive';
import { TextToImageDirective } from './text-to-image.directive';

@NgModule({
  declarations: [
    InputCheckDirective,
    TextToImageDirective,
    TextTemplateViewerDirective,
  ],
  imports: [CommonModule],
  exports: [
    InputCheckDirective,
    TextToImageDirective,
    TextTemplateViewerDirective,
  ],
})
export class DirectivesModule {}
