/* eslint-disable camelcase */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractAuthService, LoginInfo, StorageService } from 'pw-lib';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogService } from '../components/dialog/dialog.service';

/**
 * 인증(토큰) 정보 취급 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService extends AbstractAuthService {
  loginSubject$ = new BehaviorSubject(this.loginInfo);

  extFixedParams = {
    role: 'userInfo',
  };

  constructor(
    protected httpClient: HttpClient,
    protected storageService: StorageService,
    private router: Router,
    private dialogService: DialogService
  ) {
    super(
      httpClient,
      storageService,
      `${environment.apiServerUrl}/oauth/token`,
      'gloria',
      'asoyamanami:paywith1234'
    );
  }

  login(userName: string, password: string): Observable<LoginInfo> {
    return super.login(userName, password).pipe(
      tap((res) => {
        this.loginSubject$.next(res);
      })
    );
  }

  /**
   * 로그인 정보 취득
   */
  getLoginInfo$(): Observable<LoginInfo> {
    if (this.loginInfo) {
      // 클래스 내의 loginInfo 값이 있으면, info 호출
      return of(this.loginInfo);
    }

    if (!this.userName) {
      return of(null);
    }

    // 인증정보 획득
    return this.getNewAuth().pipe(
      mergeMap(() => {
        // 로그인 정보 획득
        return this.httpClient.get(`${environment.apiServerUrl}/api/userInfo`, {
          params: { userId: this.userName },
        });
      }),
      map((userInfoList: any) => {
        const [userInfo] =
          userInfoList?.content ||
          userInfoList?._embedded?.userInfoList ||
          userInfoList?._embedded?.userInfoListDtoList ||
          [];

        if (!userInfo) {
          throw new Error('Check ID or Password please.');
        }

        // 변경된 UserInfo에서는 더이상 activeFl 조회 불가능
        // 뉴글로리아 홈페이지는 서비스 종료중이므로 탈퇴자 검사 로직 실행하지 않는것으로 마무리
        // if (!userInfo.activeFl) {
        //   // TODO: 탈퇴 회원에 대한 공통 정책 수립, 서버 처리 검토
        //   this.clearAuth();
        //   this.loginSubject$.next(null);
        //   this.dialogService.alert('loginFailedByUserOut').subscribe();
        //   return null;
        // }

        this.loginInfo = userInfo;
        this.loginSubject$.next(this.loginInfo);

        // 로그인 정보 반환
        return this.loginInfo;
      }),
      filter((loginInfo) => !!loginInfo),
      catchError((e) => {
        return throwError(() => e);
      })
    );
  }

  /**
   * 로그아웃
   */
  logout(): void {
    this.clearAuth();
    this.loginSubject$.next(null);
    window.location.href = '/';
  }

  refreshLoginInfo$(): Observable<LoginInfo> {
    this.userName = this.loginInfo?.userId;
    return this.httpClient
      .get(`${environment.apiServerUrl}/api/userInfo`, {
        params: { userId: this.userName },
      })
      .pipe(
        map((userInfoList: any) => {
          const [userInfo] =
            userInfoList?.content ||
            userInfoList?._embedded?.userInfoList ||
            userInfoList?._embedded?.userInfoListDtoList ||
            [];

          this.loginInfo = { ...userInfo, userPw: undefined };

          this.loginSubject$.next(this.loginInfo);

          // 로그인 정보 반환
          return this.loginInfo;
        })
      );
  }
}
