import { Component, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { UserInfo } from 'src/app/repository/user-info/user-info.model';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-gloria-menu',
  templateUrl: './gloria-menu.component.html',
  styleUrls: ['./gloria-menu.component.scss'],
})
export class GloriaMenuComponent implements OnInit {
  /** 로그인 여부 */
  isLoggedIn: boolean;

  userInfo$: Observable<UserInfo>;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.userInfo$ = this.authService.loginSubject$.asObservable().pipe(
      tap(() => {
        this.isLoggedIn =
          this.authService.loginInfo != null &&
          this.authService.loginInfo?.certType !== 'GUEST';
      })
    );
    this.userInfo$.subscribe().unsubscribe();
  }

  onClickRefreshInfo(): void {
    this.authService.refreshLoginInfo$().subscribe();
  }
}
