import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { UiService } from 'src/app/core/services/ui.service';

@Component({
  selector: 'app-gloria-layout',
  templateUrl: './gloria-layout.component.html',
  styleUrls: ['./gloria-layout.component.scss'],
})
export class GloriaLayoutComponent implements OnInit {
  @ViewChild('sideMenu') sideMenu: MatDrawer;

  constructor(private uiService: UiService) {}

  ngOnInit(): void {
    this.uiService.mobileMunuOpen$.subscribe(() => {
      this.sideMenu.toggle();
    });
  }
}
