import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, tap } from 'rxjs';
import { UserInfoService } from 'src/app/repository/user-info/user-info.service';
import {
  matchValidator,
  passwordValidator,
} from '../../../join/join.component';
import { DialogService } from '../../dialog.service';

@Component({
  selector: 'app-find-pw-reset-form',
  templateUrl: './find-pw-reset-form.component.html',
  styleUrls: ['./find-pw-reset-form.component.scss'],
})
export class FindPwResetFormComponent implements OnInit {
  formGroup: FormGroup = this.formBuilder.group({
    pw: [
      '',
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16),
        passwordValidator(),
      ],
    ],
    confirmPw: ['', [Validators.required, matchValidator('pw')]],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialogRef: MatDialogRef<FindPwResetFormComponent>,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private userInfoService: UserInfoService
  ) {}

  ngOnInit(): void {}

  onClickCloseBtn(): void {
    this._matDialogRef.close();
  }

  onChangeClick(): void {
    if (this.formGroup.valid)
      this.userInfoService
        .changePw(this.data.userInfoId, this.formGroup.value.pw)
        .pipe(
          tap(() => {
            this.dialogService
              .alert('VALID.changePw')
              .subscribe(() => this._matDialogRef.close());
          }),
          catchError(() =>
            this.dialogService
              .alert('VALID.errChangePw')
              .pipe(tap(() => this._matDialogRef.close()))
          )
        )
        .subscribe();
  }
}
