import { registerLocaleData, ViewportScroller } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeZh from '@angular/common/locales/zh';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
} from '@angular/material/dialog';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Event, Router, Scroll } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { AuthInterceptor, LoggingInterceptor, PW_STORAGE_PREFIX } from 'pw-lib';
import { filter, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { DialogModule } from './components/dialog/dialog.module';
import { GloriaLayoutModule } from './components/gloria-layout/gloria-layout.module';
import { InputModule } from './components/input/input.module';
import { maskConfig } from './core/mask-config';
import {
  PW_UPDATE_OPTION,
  PwUpdateOption,
} from './core/services/app-update.service';
import { TranslateVersionLoader } from './core/translate-version-loader';
import { PipesModule } from './pipes/pipes.module';
import {
  BRAND_CD,
  BRAND_ID,
  BrandInterceptor,
} from './repository/brand.interceptor';
import { LanguageService } from './services/language.service';

registerLocaleData(localeKo);
registerLocaleData(localeJa);
registerLocaleData(localeZh);

const pwUpdateOption: PwUpdateOption = {
  messageTranslateKey: 'updateMessage',
  actionTranslateKey: 'updateAction',
  forceUpdate: true,
};

@NgModule({
  declarations: [
    AppComponent,
    // HomeComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (httpClient: HttpClient) =>
          new TranslateVersionLoader(httpClient),
        deps: [HttpClient],
      }, // 선택한 언어의 번역 값이 적절하게 없을 때.
      // missingTranslationHandler: {
      //   provide: MissingTranslationHandler,
      //   useClass: PwMissingTranslationHandler,
      // },
    }),
    NgxMaskModule.forRoot(maskConfig),
    DialogModule,
    PipesModule,
    InputModule,
    FlexLayoutModule,
    MatIconModule,
    GloriaLayoutModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useFactory: (authService: AuthService) => {
        return new AuthInterceptor(
          authService,
          [environment.apiServerUrl],
          // 토큰 요청 url이 포함되지 않도록 주의
          [`${environment.apiServerUrl}/oauth`]
        );
      },
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: () => {
        return new LoggingInterceptor(environment.production);
      },
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: BrandInterceptor,
      deps: [BRAND_CD, BRAND_ID],
    },
    { provide: BRAND_CD, useValue: '0006' },
    { provide: BRAND_ID, useValue: 6 },
    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
    { provide: PW_STORAGE_PREFIX, useValue: 'GLORIA_G' },
    { provide: 'showPwCoachMarkCloseBtn', useValue: false },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'JPY' },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (lang: LanguageService) => {
        return lang.lang;
      },
      deps: [LanguageService],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{ appearance: 'standard' },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: <MatDialogConfig>{ ...new MatDialogConfig(), autoFocus: false },
    },
    { provide: PW_UPDATE_OPTION, useValue: pwUpdateOption },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    let previousUrl: string;

    router.events
      .pipe(
        filter((event: Event): event is Scroll => event instanceof Scroll),
        tap((event) => {
          const [currentUrl] = router.url.split('?');

          if (event.position) {
            // 후방향 라우팅(뒤로가기)
            viewportScroller.scrollToPosition(event.position);
          } else if (event.anchor) {
            // 앵커
            viewportScroller.scrollToAnchor(event.anchor);
          } else if (currentUrl !== previousUrl) {
            // 전방향 라우팅
            viewportScroller.scrollToPosition([0, 0]);
          }

          previousUrl = currentUrl;
        })
      )
      .subscribe();
  }
}
