<div class="dialog-wrapper">
  <header class="dialog-header">
    <h2 class="dialog-title">
      {{ 'zip' | translate }}
    </h2>

    <button mat-icon-button class="dialog-close" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </header>

  <main class="dialog-main">
    <div input-wrapper [formGroup]="formGroup">
      <label input-label required> {{ 'zip' | translate }}</label>

      <div class="input-button-wrapper">
        <input
          #zipcodeInput
          formControlName="zipCode"
          inputCheck="[0-9]"
          (keyup)="onZipcodeKeyup($event)"
        />

        <button
          trnty-button
          class="search-button"
          [disabled]="formGroup.invalid || isLoading"
          (click)="getAddress()"
        >
          <ng-container *ngIf="isLoading; else loaded">
            <mat-spinner [strokeWidth]="3" [diameter]="16"></mat-spinner>
          </ng-container>

          <ng-template #loaded>
            {{ 'search' | translate }}
          </ng-template>
        </button>
      </div>

      <label input-error *ngIf="errMsg">{{ errMsg }}</label>
    </div>

    <p class="zipcode-guide">
      <!-- 입력 가이드 -->
      {{ 'VALID.numbersOnly' | translate }}
      <br />
      {{ 'zipcode.sample' | translate }}
    </p>

    <!-- 이하의 표시는 야후 재팬의 api 정책을 따른것임 -->
    <div class="yahoo-credit">
      <a target="_blank" href="https://developer.yahoo.co.jp/sitemap/">
        Web Services by Yahoo! JAPAN
      </a>
    </div>
  </main>
</div>
