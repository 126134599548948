import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, finalize, mergeMap, of, tap, timeout } from 'rxjs';
import { YahooZipCodeApi } from 'src/app/services/yahoo-zip-code.api';
import { ADDR_CODE_LIST } from 'src/app/variables';
import { ZipCodeService } from '../../../services/zip-code.service';
import { DialogService } from '../dialog.service';

export interface ZipCodeSearchResult {
  address1: string;
  address2: string;
  zipCode: string;
  fullAddress: string;
}

@Component({
  selector: 'app-search-zip-code',
  templateUrl: './search-zip-code.component.html',
  styleUrls: ['./search-zip-code.component.scss'],
})
export class SearchZipCodeComponent implements OnInit, AfterViewInit {
  constructor(
    private matDialogRef: MatDialogRef<
      SearchZipCodeComponent,
      ZipCodeSearchResult
    >,
    private zipCodeService: ZipCodeService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private zipCodeYahooApi: YahooZipCodeApi
  ) {}

  formGroup = this.formBuilder.group({
    zipCode: [null, Validators.required],
  });

  errMsg = '';

  isLoading = false;

  @ViewChild('zipcodeInput') zipcodeInput: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    this.formGroup.get('zipCode')?.valueChanges.subscribe(() => {
      this.errMsg = '';
    });
  }

  ngAfterViewInit(): void {
    this.zipcodeInput.nativeElement.focus();
  }

  onZipcodeKeyup(keyboardEvent: KeyboardEvent): void {
    if (keyboardEvent.code !== 'Enter') {
      return;
    }

    keyboardEvent.preventDefault();
    this.getAddress();
  }

  getAddress(): void {
    if (this.isLoading) {
      return;
    }

    const { zipCode } = this.formGroup.value;

    if (!zipCode) {
      this.errMsg = this.translateService.instant('VALID.numbersOnly');
      return;
    }

    const isNumbersOnly = new RegExp('^[0-9]*$').test(zipCode);

    if (!isNumbersOnly) {
      this.errMsg = this.translateService.instant('VALID.numbersOnly');
      return;
    }

    // 야후 api가 3자리까진 검색되나 정확성이 매우 떨어짐
    // 4자리부터는 정확히 일치하지 않으면 검색결과 없음
    if (zipCode.length < 4) {
      this.errMsg = this.translateService.instant('errorZipCode');
      return;
    }

    this.isLoading = true;

    this.zipCodeYahooApi
      .getAddressByZipCode(zipCode)
      .pipe(
        mergeMap(({ ResultInfo, Feature }) => {
          if (ResultInfo.Status === 200) {
            const [firstResult] = Feature || [];
            const address = firstResult?.Property?.Address;

            if (address) {
              return of(address);
            }
          }

          this.errMsg = this.translateService.instant('errorZipCode');

          return EMPTY;
        }),
        tap((fullAddress) => {
          // 도도부현
          let address1: string;
          // 나머지 주소
          let address2 = fullAddress;

          // 도도부현과 나머지 주소를 분리
          ADDR_CODE_LIST.some((prefecture) => {
            const split = (fullAddress as string).split(prefecture);

            // 도도부현과 일치하는것이 있다면 2의 길이를 가져야 함
            if (split.length >= 2) {
              // 첫번째가 빈 문자열이어야 일치하는것임
              if (split[0] === '') {
                address1 = prefecture;
                [, address2] = split;
                return true;
              }
            }

            return false;
          });

          const result: ZipCodeSearchResult = {
            address1,
            address2,
            zipCode,
            fullAddress,
          };

          this.matDialogRef.close(result);
        }),
        // 10초 타임아웃
        timeout(20000),
        catchError((error: Error) => {
          let { message } = error || {};

          // 타임아웃일때
          if (error?.name === 'TimeoutError') {
            message = 'networkConnectionError';
          }

          return this.dialogService.alert(message, 'alert');
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }
}
