import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import {
  CertCheckModel,
  CertRequestModel,
  CertResponse,
  ICert,
  certEqualsFields,
} from './cert.model';

@Injectable({
  providedIn: 'root',
})
export class CertService extends PageRepositoryService<ICert> {
  baseUri = `api/cert`;

  equalsFields: string[] = certEqualsFields;

  requestCert(
    type: 'phone' | 'email',
    data: CertRequestModel
  ): Observable<CertResponse> {
    this.isLoadingSubject.next(true);

    return this.http
      .post<CertResponse>(
        `${this.apiServerUrl}/${this.baseUri}/${type}/req`,
        data
      )
      .pipe(
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
        this.handleError()
      );
  }

  checkCert(
    type: 'phone' | 'email',
    data: CertCheckModel
  ): Observable<CertResponse> {
    this.isLoadingSubject.next(true);

    return this.http
      .post<CertResponse>(
        `${this.apiServerUrl}/${this.baseUri}/${type}/chk`,
        data
      )
      .pipe(
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
        this.handleError()
      );
  }

  /* extFixedParams */
  /* /extFixedParams */

  constructor(protected http: HttpClient) {
    super(http);
  }
}
