import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, mergeMap, tap, timeout } from 'rxjs';
import { BRAND_CD } from 'src/app/repository/brand.interceptor';
import { CertService } from 'src/app/repository/cert/cert.service';
import { UserInfoService } from 'src/app/repository/user-info/user-info.service';
import { DialogService } from '../dialog/dialog.service';

/**
 * 이메일 인증 컴포넌트
 */
@Component({
  selector: 'app-email-check',
  templateUrl: './email-check.component.html',
  styleUrls: ['./email-check.component.scss'],
})
export class EmailCheckComponent implements OnInit {
  /**
   * 회원 폼
   */
  @Input() joinFrm: FormGroup;

  /**
   * 컴포넌트 형태
   */
  @Input() mode: 'page' | 'dialog' = 'page';

  /**
   * 통과 기준, 입력한 이메일에 해당하는 회원이 있어야 하는지
   */
  @Input() isNeedSame = false;

  /**
   * 인증 통과시
   */
  @Output() onClickNext = new EventEmitter();

  /**
   * 통과하지 않고 종료시
   */
  @Output() onClickCancel: EventEmitter<any> = new EventEmitter();

  /**
   * 인증번호 인풋 엘리먼트
   */
  @ViewChild('authenticationNum')
  authenticationNum: ElementRef<HTMLInputElement>;

  /**
   * 인증 메일 전송 여부
   */
  isSubmitEmail = false;

  /**
   * 인증 레코드 아이디
   */
  certId: any;

  // TODO: 용도 확인
  /**
   * @deprecated 용도 불명
   */
  remainTimeTo = 0;

  /**
   * 인증 유효시간
   */
  remainTime = 0;

  /**
   * 인증 유효시간 타이머
   */
  interval: any;

  /**
   * 인증 이메일 전송 로딩
   */
  isSubmitLoading = false;

  /**
   * 인증 폼
   */
  authenticationFrm = this.formBuilder.group({
    authenticationNum: this.formBuilder.control('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
    ]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private certService: CertService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private userInfoService: UserInfoService,
    @Inject(BRAND_CD)
    private brandCd: string
  ) {}

  ngOnInit(): void {
    this.authenticationFrm.disable();

    if (this.certId) {
      this.authenticationFrm.enable();
      this.isSubmitEmail = true;
    }
  }

  /**
   * 이메일 인풋에서 키업시
   */
  onEmailKeyup(keyboardEvent: KeyboardEvent): void {
    // 엔터면
    if (keyboardEvent.key === 'Enter') {
      keyboardEvent.stopPropagation();
      keyboardEvent.preventDefault();
      // 이메일 전송
      this.submitEmail();
    }
  }

  /**
   * 이메일 전송 버튼 클릭시
   */
  submitEmail(): void {
    const emailControl = this.joinFrm.get('email');

    if (emailControl.invalid) {
      emailControl.markAllAsTouched();
      return;
    }

    // 로딩중이면
    if (this.isSubmitLoading) {
      // 종료
      return;
    }

    this.isSubmitEmail = true;
    this.isSubmitLoading = true;

    this.certService
      .requestCert('email', {
        brandCd: this.brandCd,
        corpNo: '0',
        receiver: emailControl.value,
        reqType: 'CODE',
      })
      .pipe(
        tap((res) => {
          const minute = Math.min(res.valid / 60);
          this.remainTimeTo = Date.now() + minute * 60 * 1000;
          this.certId = res.certId;
          // 타이머 시작
          this.initInterval();
          // 이메일 수정 불가
          this.joinFrm.get('email').disable();
          // 인증번호 수정 가능
          this.authenticationFrm.enable();
          // 인증번호 창 포커스
          this.authenticationNum?.nativeElement?.focus();
        }),
        // 10초 타임아웃
        timeout(20000),
        catchError((error: Error) => {
          // 오류시 메일 전송 플래그 원복
          this.isSubmitEmail = false;
          let { message } = error || {};

          // 타임아웃일때
          if (error?.name === 'TimeoutError') {
            message = 'networkConnectionError';
          }

          return this.dialogService.alert(message, 'alert');
        }),
        finalize(() => {
          // 로딩 종료
          this.isSubmitLoading = false;
        })
      )
      .subscribe();
  }

  /**
   * 인증번호 인풋에서 키업시
   */
  onCertKeyup(keyboardEvent: KeyboardEvent): void {
    // 엔터면
    if (keyboardEvent.key === 'Enter') {
      keyboardEvent.stopPropagation();
      keyboardEvent.preventDefault();
      // 인증 검사
      this.submitCert();
    }
  }

  /**
   * 인증번호 검사 버튼 클릭시
   */
  submitCert(): void {
    // 이메일 전송하지 않았으면
    if (!this.isSubmitEmail) {
      // 종료
      return;
    }

    // 로딩중이면
    if (this.isSubmitLoading) {
      // 종료
      return;
    }

    this.isSubmitLoading = true;

    this.certService
      .checkCert('email', {
        brandCd: this.brandCd,
        corpNo: '0',
        receiver: this.joinFrm.get('email').value,
        certId: this.certId,
        certNum: this.authenticationFrm.get('authenticationNum').value,
      })
      .pipe(
        tap((res) => {
          // 검사 통과시
          if (res.result) {
            // 이메일 검사
            this.onNextClick();
          } else {
            this.authenticationFrm.get('authenticationNum').patchValue('');
            this.dialogService
              .alert(
                this.translateService.instant('VALID.VerificationIncorrect')
              )
              .subscribe();
          }
        }),
        finalize(() => {
          // 로딩 종료
          this.isSubmitLoading = false;
        })
      )
      .subscribe();
  }

  /**
   * 인증번호 검사 통과 후 이메일 검사
   */
  onNextClick(): void {
    // 이메일 검사
    this.userInfoService
      .emailCheck(this.joinFrm.value.email)
      .pipe(
        mergeMap(({ userInfoIdList }) => {
          // 검색 결과가 없어야 회원가입 가능
          const canJoin = !this.isNeedSame && !userInfoIdList?.length;
          // 검색 결과가 있어야 비밀번호 재설정 가능
          const canResetPassword = this.isNeedSame && !!userInfoIdList?.length;

          // 통과
          if (canJoin || canResetPassword) {
            return this.dialogService
              .alert(this.translateService.instant('VALID.checkEmail'))
              .pipe(tap(() => this.onClickNext.emit(userInfoIdList[0])));
          }

          // 가입되지 않아 재설정 불가
          if (this.isNeedSame) {
            return this.dialogService
              .alert(this.translateService.instant('VALID.emailNotFound'))
              .pipe(tap(() => this.reset()));
          }

          // 중복되어 가입 불가
          return this.dialogService
            .alert(this.translateService.instant('VALID.sameEmail'))
            .pipe(tap(() => this.reset()));
        })
      )
      .subscribe();
  }

  /**
   * 닫기 버튼 클릭시
   */
  onCancelClick(): void {
    this.onClickCancel.emit();
  }

  /**
   * 이메일이 도착하지 않는 경우 클릭시
   */
  onEmailNotificationClick(): void {
    this.dialogService.openEmailNotReceivingNotification().subscribe();
  }

  /**
   * 유효시간 타이머 시작
   */
  private initInterval(): void {
    this.remainTime = this.remainTimeTo - Date.now();

    if (this.remainTime <= 0) {
      this.authenticationFrm.disable();
      this.certId = null;
      return;
    }

    this.interval = setInterval(() => {
      this.remainTime = this.remainTimeTo - Date.now();

      if (this.remainTime <= 0) {
        this.reset();
      }
    }, 1000);
  }

  /**
   * 초기화
   */
  private reset(): void {
    // 이메일 수정 가능
    this.joinFrm.get('email').enable();
    // 인증번호 초기화
    this.authenticationFrm.disable();
    this.authenticationFrm.reset();
    this.initTimer();
  }

  /**
   * 타이머 초기화
   */
  private initTimer(): void {
    this.authenticationFrm.disable();
    this.authenticationFrm.reset();
    this.isSubmitEmail = false;
    this.certId = null;
    this.remainTimeTo = 0;
    this.remainTime = 0;
    clearInterval(this.interval);
  }
}
