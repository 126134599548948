import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, tap } from 'rxjs';
import { DialogService } from 'src/app/components/dialog/dialog.service';
import { Utils } from 'src/app/core/utils';
import { BRAND_ID } from 'src/app/repository/brand.interceptor';
import { GloriaUserInfoService } from 'src/app/services/gloria-user-info.service';
import { ZipCodeService } from '../../services/zip-code.service';
import { ADDR_CODE_LIST } from '../../variables';

export const passwordValidator = (): ValidatorFn | null => {
  return ({ value }): ValidationErrors | null => {
    if (!value) {
      return { pattern: true };
    }

    let unknownCount = 0;
    const patternSet = new Set();

    const split = ((value || '') as string).split('');
    split.forEach((char) => {
      if (new RegExp('[a-z]').test(char)) {
        patternSet.add(1);
        return;
      }

      if (new RegExp('[A-Z]').test(char)) {
        patternSet.add(2);
        return;
      }

      if (new RegExp('[0-9]').test(char)) {
        patternSet.add(3);
        return;
      }

      if (
        new RegExp(
          '[!"`\'#%&,:;<>=@{}~_\\.\\-\\$\\(\\)\\*\\+\\/\\\\?\\[\\]\\^\\|]+$'
        ).test(char)
      ) {
        patternSet.add(4);
        return;
      }

      unknownCount += 1;
    });

    if (unknownCount > 0) {
      return { pattern: true };
    }

    if (patternSet.size < 3) {
      return { pattern: true };
    }

    return null;
  };
};

export const matchValidator = (matchTo: string): ValidatorFn | null => {
  return (control: AbstractControl): ValidationErrors | null => {
    return control?.parent?.get(matchTo)?.value === control?.value
      ? null
      : { match: true };
  };
};

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss'],
})
export class JoinComponent implements OnInit {
  /** 이메일 인증 여부 */
  emailAuthenticationFl = false;

  /** 서비스 이용약관 체크 여부 */
  servicePolicyFl = false;

  /** 서비스 이용약관 펼침 여부 */
  serviceOpenFl = false;

  /** 개인정보 이용약관 체크 여부 */
  privacyPolicyFl = false;

  /** 개인정보 이용약관 펼침 여부 */
  privacyOpenFl = false;

  /** 이용약관 미동의 안내문구 표시여부 */
  policyErrorShowFl = false;

  joinFrm: FormGroup = this._formBuilder.group({
    email: this._formBuilder.control(null, [
      Validators.required,
      Validators.email,
    ]),
    // userId: [null, Validators.required],
    pw: [
      null,
      [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(16),
        passwordValidator(),
      ],
    ],
    confirmPw: [null, [Validators.required, matchValidator('pw')]],
    familyName: [null, Validators.required],
    firstName: [null, Validators.required],
    // 후리가나 성
    familyName2: [null, Validators.required],
    // 후리가나 명
    firstName2: [null, Validators.required],
    // TODO: birthDt 2000-01-21 형식으로 바꿔야 함
    year: [null, Validators.required],
    month: ['1', Validators.required],
    day: ['1', Validators.required],
    gender: [null, Validators.required],
    telNum: [null, Validators.required],

    zipCode: [null, Validators.required],
    address1: ['', Validators.required],
    address2: [null, Validators.required],
  });

  /** 회원 주소지로 사용할 일본 행정구역 목록 */
  ADDR_CODE_LIST = ADDR_CODE_LIST;

  /** 금일 날짜 변수 */
  today = new Date();

  /** 말일 날짜 변수 */
  lastDay = 31;

  isLoading = false;

  constructor(
    // private _userService:UserService
    private _formBuilder: FormBuilder,
    private router: Router,
    private userInfoService: GloriaUserInfoService,
    private zipCodeService: ZipCodeService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    @Inject(BRAND_ID)
    private brandId: string
  ) {}

  ngOnInit(): void {}

  backHistory(): void {
    history.go(-1);
  }

  onOpenSearchZipCode(): void {
    this.dialogService
      .searchZipCode()
      .subscribe(({ address1, address2, zipCode }) => {
        this.joinFrm.patchValue({
          zipCode,
          address1,
          address2,
        });
      });
  }

  onClickNext(): void {
    this.emailAuthenticationFl = true;
  }

  /** 회원가입 클릭 시, 회원 정보 저장 요청 */
  onClickJoin(): void {
    if (this.isLoading) {
      return;
    }

    this.policyErrorShowFl = true;
    this.joinFrm.markAllAsTouched();

    if (!this.joinFrm.valid || !this.privacyPolicyFl || !this.servicePolicyFl) {
      this.scrollToFirstError();
      return;
    }

    const joinFrmValue = this.joinFrm.getRawValue();
    joinFrmValue.birthDt = `${joinFrmValue.year}-${joinFrmValue.month.padStart(
      2,
      '0'
    )}-${joinFrmValue.day.padStart(2, '0')}`;

    const params = {
      gender: joinFrmValue.gender,
      userNm: `${joinFrmValue.familyName}${joinFrmValue.firstName}`,
      certType: 'EMAIL',
      userId: joinFrmValue.email,
      userPw: joinFrmValue.pw,
      brthD: joinFrmValue.day,
      brthM: joinFrmValue.month,
      brthY: joinFrmValue.year,
      birthday: joinFrmValue.birthDt,
      firstName: joinFrmValue.firstName,
      familyName: joinFrmValue.familyName,
      firstName2: joinFrmValue.firstName2,
      familyName2: joinFrmValue.familyName2,
      address1: joinFrmValue.address1,
      address2: joinFrmValue.address2,
      mobileNum: joinFrmValue.telNum,
      zipcode: joinFrmValue.zipCode,
      email: joinFrmValue.email,
      brandId: this.brandId,
      brand: { id: this.brandId },
    };

    this.isLoading = true;

    this.userInfoService
      .create(params)
      .pipe(
        tap(() => {
          this.dialogService
            .alert(`${this.translateService.instant('MSG.createUserComplete')}`)
            .subscribe(() => this.router.navigate(['home']));
        }),
        catchError((e) => {
          // TODO: 네트워크 오류 처리
          return this.dialogService.alert(
            this.translateService.instant(`MSG.${e?.message}`),
            this.translateService.instant('alert')
          );
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  /**
   * 비밀번호 정규식 유효 확인 (영어,숫자 포함 8 ~ 16 자 이내)
   * @param password
   */
  validatePassword(password: string): boolean {
    return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,16}$/.test(password);
  }

  /** 이용약관 전체동의 */
  onClickAllAgree(): void {
    if (!this.privacyPolicyFl || !this.servicePolicyFl) {
      // 미동의 하나라도 있다면 전부 체크
      this._chkAll(true);
    } else {
      this._chkAll(false);
    }
  }

  /** 전체 이용약관 변경 */
  private _chkAll(fl: boolean): void {
    this.servicePolicyFl = fl;
    this.privacyPolicyFl = fl;
  }

  /** 이용약관 우측 화살표 클릭 시 */
  onClickPolicyArrow(type: string, event: MouseEvent): void {
    if (type === 'service') {
      this.serviceOpenFl = !this.serviceOpenFl;
    } else if (type === 'privacy') {
      this.privacyOpenFl = !this.privacyOpenFl;
    }
    // event.stopPropagation();
  }

  isInputValid(formControl: AbstractControl): boolean {
    if (!formControl.value) {
      return true;
    }
    return formControl.valid;
  }

  /** 월 변경 시 말일 계산후 수정 */
  onChangeMonth(month: string): void {
    this.lastDay = new Date(
      this.today.getFullYear(),
      Number(month),
      0
    ).getDate();
    const dayForm: AbstractControl = this.joinFrm.controls.day;
    if (dayForm.value > this.lastDay) {
      dayForm.setValue(this.lastDay.toString());
    }
  }

  private scrollToFirstError(): void {
    setTimeout(() => {
      const htmlCollection: HTMLCollection =
        window.document.getElementsByClassName('error-text-box');

      if (htmlCollection.length < 1) {
        return;
      }

      const firstElement = htmlCollection.item(0) as HTMLElement;
      let top = firstElement?.offsetTop || 0;

      if (!Utils.isMobileSize()) {
        top = top - 152 - 80;
      } else {
        top = top - 56 - 80;
      }

      window.scrollTo({ top, behavior: 'smooth' });
    });
  }
}
