import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { DrawerModule } from '../drawer/drawer.module';
import { GloriaFooterModule } from '../gloria-footer/gloria-footer.module';
import { GloriaHeaderModule } from '../gloria-header/gloria-header.module';
import { GloriaMenuModule } from '../gloria-menu/gloria-menu.module';
import { GloriaTabBarModule } from '../gloria-tab-bar/gloria-tab-bar.module';
import { GloriaLayoutComponent } from './gloria-layout.component';

@NgModule({
  declarations: [GloriaLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    GloriaHeaderModule,
    GloriaFooterModule,
    GloriaMenuModule,
    GloriaTabBarModule,
    MatSidenavModule,
    DrawerModule,
  ],
})
export class GloriaLayoutModule {}
