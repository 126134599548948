import { startCase } from 'lodash-es';

/**
 * 각종 유용한 기능들 모음
 */
export class Utils {
  /**
   * 문자인증에 사용되는 uuid
   */
  static getUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // y의 자리에는 8, 9, a, b 가능
      const v = c === 'x' ? Math.random() * 16 : Math.random() * 4 + 8;
      return Math.floor(v).toString(16);
    });
  }

  /**
   * 플러터 웹앱 여부
   */
  static isApp(): boolean {
    return navigator.userAgent === 'paywith-flutter-app';
  }

  /**
   * 모바일(웹/앱) 여부를 화면 크기로 판단
   */
  static isMobileSize(): boolean {
    return window.innerWidth <= 768;
  }

  /**
   * userAgent가 모바일 기기인지 확인
   */
  static isMobileBrowser(): boolean {
    const regex =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i;
    const isMobile = regex.test(navigator?.userAgent);

    return isMobile;
  }

  /**
   * 문자열 포멧 설정
   *
   * (\u200b(숫자)\u200b) 형식을
   * (<a target="_blank" href="/booking?id=(숫자)&bookingStateCodeIn=">(숫자)</a>) 으로 변경
   *
   * @example (180501) -> (<a target="_blank" href="/booking?id=180501&bookingStateCodeIn=">180501</a>)
   */
  static getFormattedRemarks(text: string): string {
    if (!text) {
      return null;
    }

    return text.replace(
      /\(\u200b(\d+)\u200b\)/g,
      `(<a target="_blank" href="/booking?id=$1&bookingStateCodeIn=">$1</a>)`
    );
  }

  static getStringifiedJson(parsed: any, fallback = ''): string {
    if (!parsed) {
      return fallback;
    }

    try {
      return JSON.stringify(parsed);
    } catch (error) {
      return fallback;
    }
  }

  static getParsedJson(stringified: string, fallback = null): any {
    if (!stringified) {
      return fallback;
    }

    try {
      return JSON.parse(stringified);
    } catch (error) {
      return fallback;
    }
  }

  /**
   * 언어 접미사 붙은 데이터 키 반환
   * @param key 원본 데이터 키
   * @param language 언어
   */
  static getDataI18nKey(
    key: string,
    language: string,
    isKoreanNeedSuffix = false
  ): string {
    // 한국어이고 접미사 필요 없으면 키 그대로 반환
    if (language === 'ko' && !isKoreanNeedSuffix) {
      return key;
    }

    // 소문자 변환
    const lowerCase = language.toLowerCase();
    // 코드 분리 zh-CN
    const hyphenSplitList = lowerCase.split('-');
    let firstCapitalized = '';

    hyphenSplitList.forEach((hyphenSplit) => {
      firstCapitalized += startCase(hyphenSplit);
    });

    return `${key}${firstCapitalized}`;
  }

  /**
   * 데이터에서 원하는 번역 반환
   * @param data 데이터
   * @param key 데이터 키
   * @param language 찾는 언어
   * @param fallback 찾는 값이 없을때 반환할 값 기본은 `data[key]`
   * @returns 찾는 값 없으면 `fallback` 반환
   */
  static getDataI18n(
    data: any,
    key: string,
    language: string,
    fallback = data[key]
  ): any {
    const keyName = Utils.getDataI18nKey(key, language, true);
    return data[keyName] || fallback;
  }
}
