import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { DialogModule } from '../dialog/dialog.module';
import { SharedModule } from '../shared/shared.module';
import { GloriaHeaderComponent } from './gloria-header.component';

@NgModule({
  declarations: [GloriaHeaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    SharedModule,
    DialogModule,
  ],
  exports: [GloriaHeaderComponent],
})
export class GloriaHeaderModule {}
