import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { GloriaContentSectionModule } from '../gloria-content-section/gloria-content-section.module';
import { InputWrapperModule } from '../input-wrapper/input-wrapper.module';
import { LoadingModule } from '../loading/loading.module';
import { SharedModule } from '../shared/shared.module';
import { EmailCheckComponent } from './email-check.component';

@NgModule({
  declarations: [EmailCheckComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputWrapperModule,
    GloriaContentSectionModule,
    LoadingModule,
    SharedModule,
  ],
  exports: [EmailCheckComponent],
})
export class EmailCheckModule {}
