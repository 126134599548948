<footer class="footer-compay-wrap">
  <!-- 로고 -->
  <img src="/assets/img/footer-ci.png" class="footer-ci" />

  <!-- 사명 -->
  <span class="company-name">
    {{ 'newGloria' | translate }}
  </span>

  <div class="sns-icon-warpper">
    <!-- 인스타그램 아이콘 -->
    <a
      class="img-caption-btn"
      href="https://www.instagram.com/new__gloria/"
      target="_blank"
    >
      <div class="instagram-icon">
        <img class="icon" src="/assets/img/instagram-icon.png" />
      </div>
    </a>

    <!-- 라인 아이콘 -->
    <a
      class="img-caption-btn"
      href="https://liff.line.me/1645278921-kWRPP32q/?accountId=135ztycd"
      target="_blank"
    >
      <div class="line-icon">
        <img class="icon" src="/assets/img/line-icon.png" />
      </div>
    </a>
  </div>

  <!-- 시설 목록 -->
  <div class="facility-list">
    <!-- 료칸 -->
    <span class="facility-title">
      {{ 'ryokan' | translate }}
    </span>

    <!-- 료칸 목록 -->
    <div class="facility-ryokan">
      <a
        class="facility-item"
        *ngFor="let topRyokan of topRyokanList"
        (click)="onClick(topRyokan)"
      >
        | {{ topRyokan | dataI18n: 'mrhstNm' }}
      </a>

      |
    </div>

    <!-- 쿠라시 -->
    <span class="facility-title">
      {{ 'kurashi' | translate }}
    </span>

    <!-- 쿠라시 목록 -->
    <div class="facility-kurashi">
      <a
        class="facility-item"
        *ngFor="let topFacility of topFacilityList"
        (click)="onClick(topFacility)"
      >
        | {{ topFacility | dataI18n: 'mrhstNm' }}
      </a>

      |
    </div>
  </div>

  <!-- 이용약관 -->
  <div class="rule">
    <!-- 타비요리클럽 -->
    <a
      class="rule-item"
      href="/assets/pdf/旅日和倶楽部規約-20230814.pdf"
      target="_blank"
      >{{ 'tabiMembershipAgreement' | translate }}</a
    >|

    <!-- 개인정보 -->
    <a class="rule-item" routerLink="/document/terms" routerLinkActive="on">{{
      'privacyTerms' | translate
    }}</a
    >|

    <!-- 취소 수수료 -->
    <a class="rule-item" routerLink="/document/refund">{{
      'cancelRule' | translate
    }}</a>
  </div>

  <span class="company-location">
    <!-- 주소 -->
    {{ 'newGloriaAddr' | translate }}
  </span>

  <!-- 저작권 -->
  <span class="copy-right">
    Copyright © New gloria resort group All Rights Reserved.
  </span>
</footer>
