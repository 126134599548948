import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { GloriaLayoutComponent } from './components/gloria-layout/gloria-layout.component';

const routes: Routes = [
  {
    path: 'landing',
    loadChildren: () =>
      import('./components/landing/landing.module').then(
        (m) => m.LandingModule
      ),
  },
  {
    path: '',
    component: GloriaLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'unauthorized',
        loadChildren: () =>
          import('./components/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./components/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'home2',
        loadChildren: () =>
          import('./components/new-home/new-home.module').then(
            (m) => m.NewHomeModule
          ),
      },
      {
        path: 'my',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/my/my.module').then((m) => m.MyModule),
      },
      {
        path: 'cs',
        loadChildren: () =>
          import('./components/cs/cs.module').then((m) => m.CsModule),
      },
      {
        path: 'chrg',
        loadChildren: () =>
          import('./components/chrg/chrg.module').then((m) => m.ChrgModule),
      },
      {
        path: 'rsrv',
        loadChildren: () =>
          import('./components/rsrv/rsrv.module').then((m) => m.RsrvModule),
      },
      {
        path: 'facility',
        loadChildren: () =>
          import('./components/facility/facility.module').then(
            (m) => m.FacilityModule
          ),
      },
      {
        path: 'join',
        loadChildren: () =>
          import('./components/join/join.module').then((m) => m.JoinModule),
      },
      {
        path: 'mobile-my',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/mobile-my/mobile-my.module').then(
            (m) => m.MobileMyModule
          ),
      },
      {
        path: 'ordr',
        loadChildren: () =>
          import('./components/ordr/ordr.module').then((m) => m.OrdrModule),
      },
      {
        path: 'tabi',
        loadChildren: () =>
          import('./components/tabi/tabi.module').then((m) => m.TabiModule),
      },
      {
        path: 'company',
        loadChildren: () =>
          import('./components/company/company.module').then(
            (m) => m.CompanyModule
          ),
      },
      {
        path: 'recruit',
        loadChildren: () =>
          import('./components/recruit/recruit.module').then(
            (m) => m.RecruitModule
          ),
      },
      {
        path: 'document',
        loadChildren: () =>
          import('./components/document/document.module').then(
            (m) => m.DocumentModule
          ),
      },
      {
        path: 'video',
        loadChildren: () =>
          import('./components/intro-video/intro-video.module').then(
            (m) => m.IntroVideoModule
          ),
      },
      {
        path: 'campaign',
        loadChildren: () =>
          import('./components/campaign/campaign.module').then(
            (m) => m.CampaignModule
          ),
      },
    ],
  },
  { path: 'azumaya', redirectTo: '/facility/1090' },
  { path: 'sakuratei', redirectTo: '/facility/1091' },
  { path: 'mutsuki', redirectTo: '/facility/1092' },
  { path: 'iroha', redirectTo: '/facility/1093' },
  { path: 'yuyu', redirectTo: '/facility/1094' },
  { path: 'ougiyama', redirectTo: '/facility/1095' },
  { path: 'sagiritei', redirectTo: '/facility/1096' },
  { path: 'kanputei', redirectTo: '/facility/1097' },
  { path: 'yufuintei', redirectTo: '/facility/1098' },
  { path: 'sendo', redirectTo: '/facility/1099' },
  { path: 'shikian', redirectTo: '/facility/1100' },
  { path: 'sourin', redirectTo: '/facility/1101' },
  { path: 'kyuan', redirectTo: '/facility/1102' },
  { path: 'resort-yufuin', redirectTo: '/facility/1103' },
  { path: 'gloria-oita', redirectTo: '/facility/1104' },
  { path: 'g-palace', redirectTo: '/facility/1105' },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
