import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

/**
 * 캔버스 태그를 이용해 텍스트를 이미지로 변환하는 디렉티브
 */
@Directive({
  selector: 'canvas[textToImage]',
})
export class TextToImageDirective implements AfterViewInit {
  /**
   * 텍스트
   */
  @Input() textToImage: string;

  /**
   * 폰트 크기
   */
  @Input() fontSize = 14;

  /**
   * 폰트
   */
  @Input() fontFamily = 'Meiryo';

  /**
   * 너비
   */
  @Input() width = (this.fontSize || 14) * 8;

  constructor(private elementRef: ElementRef<HTMLCanvasElement>) {}

  ngAfterViewInit(): void {
    const canvas = this.elementRef.nativeElement;
    const context = canvas.getContext('2d');
    canvas.width = this.width;
    canvas.height = this.fontSize + 2;
    context.font = `${this.fontSize}px ${this.fontFamily}`;
    context.fillText(this.textToImage, 0, this.fontSize, this.width);
  }
}
