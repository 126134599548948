import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { PwDirectiveModule } from 'pw-lib';
import { SharedModule } from '../shared/shared.module';
import { GloriaMenuComponent } from './gloria-menu.component';

@NgModule({
  declarations: [GloriaMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatIconModule,
    MatTabsModule,
    PwDirectiveModule,
  ],
  exports: [GloriaMenuComponent],
})
export class GloriaMenuModule {}
