<ng-container *ngIf="!emailAuthenticationFl">
  <app-gloria-content-section>
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="33px"
      fxLayoutGap.lt-sm="28px"
    >
      <img
        class="header-icon"
        ngClass.lt-sm="mobile"
        src="/assets/img/add-user-icon.png"
      />
      <span class="header-title" ngClass.lt-sm="mobile">
        {{ 'join.inputEmail' | translate }}
      </span>
    </div>

    <!-- TODO: 언어마다 줄바꿈이 다른점, html태그가 필요한점 등 자체 번역 검토. 지오씨쪽에서 관련 규칙에 대한 검토 필요 -->
    <div class="email-guide">
      ご登録するメールアドレスを入力のうえ、
      <br />
      メール認証をクリックしてください。
      <br />
      <br />
      ご入力いただいたメールアドレスに
      <br />
      6桁の認証番号が届きますので、認証番号の欄にご入力をお願いいたします。
      <br />
    </div>
  </app-gloria-content-section>

  <app-email-check
    [joinFrm]="joinFrm"
    (onClickNext)="onClickNext()"
    (onClickCancel)="backHistory()"
  ></app-email-check>
</ng-container>

<ng-container *ngIf="emailAuthenticationFl">
  <app-gloria-content-section>
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="33px"
      fxLayoutGap.lt-sm="28px"
    >
      <img
        class="header-icon"
        ngClass.lt-sm="mobile"
        src="/assets/img/add-user-icon.png"
      />
      <span class="header-title" ngClass.lt-sm="mobile">
        {{ 'inputUserInfo' | translate }}
      </span>
    </div>

    <div class="input-group" [formGroup]="joinFrm">
      <div fxLayout="column" fxLayoutGap="17px">
        <!-- <app-input-wrapper
        [inputTitle]="'id' | translate"
        [requiredFl]="true"
        [inputFormControls]="[joinFrm.controls.userId]"
        [errorText]="'VALID.id' | translate"
      >
        <input
          id="id"
          class="font-body"
          fxFlex="1 1 0"
          type="text"
          formControlName="userId"
          placeholder="{{ 'id' | translate }}"
        />
      </app-input-wrapper> -->

        <app-input-wrapper
          [inputTitle]="'emailAsId' | translate"
          [requiredFl]="true"
          [inputFormControls]="[joinFrm.controls.email]"
          [errorText]="'VALID.email' | translate"
        >
          <input
            id="email"
            class="font-body disabled"
            readonly
            fxFlex="1 1 0"
            type="email"
            formControlName="email"
            placeholder="{{ 'email' | translate }}"
          />
        </app-input-wrapper>

        <!-- <div>
          <span class="input-title">{{ 'certNum' | translate }}</span>
          <div fxLayout="row" fxLayoutGap="8px">
            <input
              fxFlex="1 1 100%"
              id="certifyNum"
              formControlName="certifyNum"
              class="input"
              placeholder="{{ 'inputCertNumNDigit' | translate: { n: 4 } }}"
            />
            <button class="btn-outline-blue">{{ 'check' | translate }}</button>
          </div>
          <div class="error-text-box" *ngIf="joinFrm.controls.certifyNum.valid">
            <p class="error-text">{{ 'wrongCert' | translate }}</p>
          </div>
        </div> -->

        <app-input-wrapper
          [inputTitle]="'pw' | translate"
          [requiredFl]="true"
          [inputFormControls]="[joinFrm.controls.pw]"
          [errorText]="'VALID.pw' | translate"
        >
          <input
            id="pw"
            class="font-body"
            type="password"
            fxFlex="1 1 0"
            formControlName="pw"
            placeholder="{{ 'inputPw' | translate }}"
          />
        </app-input-wrapper>

        <app-input-wrapper
          [inputTitle]="'checkPw' | translate"
          [requiredFl]="true"
          [inputFormControls]="[joinFrm.controls.confirmPw]"
          [errorText]="'VALID.confirmPw' | translate"
        >
          <input
            id="confirmPw"
            class="font-body"
            type="password"
            fxFlex="1 1 0"
            formControlName="confirmPw"
            placeholder="{{ 'inputConfirmPw' | translate }}"
          />
        </app-input-wrapper>

        <app-input-wrapper
          inputTitle="{{ 'name' | translate }}(
          {{ 'langChOrLangEn' | translate }})"
          [requiredFl]="true"
          gap="8px"
          [inputFormControls]="[
            joinFrm.controls.familyName,
            joinFrm.controls.firstName
          ]"
          [errorText]="'VALID.name' | translate"
        >
          <input
            fxFlex="8 1 0"
            id="familyName"
            class="font-body flex-2"
            type="text"
            formControlName="familyName"
            placeholder="{{ 'lastNm' | translate }}"
          />
          <input
            fxFlex="17 1 0"
            id="firstName"
            class="font-body flex-2"
            type="text"
            formControlName="firstName"
            placeholder="{{ 'firstNm' | translate }}"
          />
        </app-input-wrapper>

        <app-input-wrapper
          inputTitle="{{ 'namePronunciation' | translate }}"
          [requiredFl]="true"
          gap="8px"
          [inputFormControls]="[
            joinFrm.controls.familyName2,
            joinFrm.controls.firstName2
          ]"
          [errorText]="'VALID.namePronunciation' | translate"
        >
          <input
            fxFlex="8 1 0"
            id="familyName2"
            class="font-body flex-2"
            type="text"
            formControlName="familyName2"
            placeholder="{{ 'lastNm' | translate }}"
          />
          <input
            fxFlex="17 1 0"
            id="firstName2"
            class="font-body flex-2"
            type="text"
            formControlName="firstName2"
            placeholder="{{ 'firstNm' | translate }}"
          />
        </app-input-wrapper>

        <app-input-wrapper
          inputTitle="{{ 'birthDt' | translate }}"
          [requiredFl]="true"
          gap="8px"
          [inputFormControls]="[
            joinFrm.controls.year,
            joinFrm.controls.month,
            joinFrm.controls.day
          ]"
          [errorText]="'VALID.birthDt' | translate"
        >
          <input
            maxlength="4"
            fxFlex="1 1 0"
            id="year"
            class="font-body flex-3"
            formControlName="year"
            placeholder="年"
          />
          <select
            fxFlex="1 1 0"
            id="month"
            class="font-body flex-3"
            formControlName="month"
            #month
            (change)="onChangeMonth(month.value)"
          >
            <option [value]="null" disabled hidden>月</option>
            <option
              *ngFor="let item of [].constructor(12); index as i"
              [value]="i + 1"
            >
              {{ i + 1 }}
            </option>
          </select>
          <select
            fxFlex="1 1 0"
            id="day"
            class="font-body flex-3"
            formControlName="day"
          >
            <option [value]="null" disabled hidden>日</option>
            <option
              *ngFor="let item of [].constructor(lastDay); index as i"
              [value]="i + 1"
            >
              {{ i + 1 }}
            </option>
          </select>
        </app-input-wrapper>

        <app-input-wrapper
          inputTitle="{{ 'gender' | translate }}"
          [requiredFl]="true"
          gap="60px"
          [inputFormControls]="[joinFrm.controls.gender]"
          [errorText]="'VALID.gender' | translate"
        >
          <div fxLayout="row" fxLayoutAlign="flex-start center">
            <input
              type="radio"
              name="gender"
              id="male"
              class="font-body"
              formControlName="gender"
              value="MALE"
            />
            <label class="gender" for="male">{{ 'male' | translate }}</label>
          </div>
          <div fxLayout="row" fxLayoutAlign="flex-start center">
            <input
              type="radio"
              name="gender"
              id="female"
              class="font-body"
              formControlName="gender"
              value="FEMALE"
            />
            <label class="gender" for="female">{{
              'female' | translate
            }}</label>
          </div>
        </app-input-wrapper>

        <!-- <div>
          <span class="input-title">
            {{ 'mainContact' | translate }}
            <span class="required">*</span></span
          >
          <div fxLayout="row" fxLayoutGap="8px">
            <input
              class="input"
              fxFlex="1 1 0"
              id="mainContact"
              formControlName="mainContact"
              placeholder="{{ 'PLACEHOLDER.contact' | translate }}"
            />
          </div>
          <div class="notice-text">※ {{ 'mainContactHint' | translate }}</div>
          <div class="error-text-box" *ngIf="!joinFrm.controls.mainContact.valid">
            <p class="error-text">{{ 'VALID.mainContact' | translate }}</p>
          </div>
        </div> -->

        <app-input-wrapper
          [inputTitle]="'telNum' | translate"
          [requiredFl]="true"
          [inputFormControls]="[joinFrm.controls.telNum]"
          [errorText]="'VALID.mainContact' | translate"
          [noticeText]="'mainContactHint' | translate"
        >
          <input
            id="telNum"
            class="font-body"
            fxFlex="1 1 0"
            formControlName="telNum"
            type="text"
            placeholder="{{ 'PLACEHOLDER.contact' | translate }}"
          />
        </app-input-wrapper>

        <app-input-wrapper
          inputTitle="{{ 'zip' | translate }}"
          [requiredFl]="true"
          [inputFormControls]="[joinFrm.controls.zipCode]"
          [errorText]="'VALID.required' | translate"
        >
          <input
            style="cursor: pointer"
            fxFlex="1 1"
            readonly
            id="zipCode"
            class="font-body"
            type="text"
            (click)="onOpenSearchZipCode()"
            formControlName="zipCode"
          />
        </app-input-wrapper>

        <app-input-wrapper
          inputTitle="{{ 'prefecture' | translate }}"
          [requiredFl]="true"
          [inputFormControls]="[joinFrm.controls.address1]"
          [errorText]="'VALID.required' | translate"
        >
          <select
            fxFlex="1 1"
            id="address1"
            formControlName="address1"
            class="font-body"
          >
            <option [value]="''" selected>-</option>
            <option [value]="v" *ngFor="let v of ADDR_CODE_LIST">
              {{ 'dodofuken.' + v | translate }}
            </option>
          </select>
        </app-input-wrapper>

        <app-input-wrapper
          inputTitle="{{ 'address' | translate }}"
          [requiredFl]="true"
          [inputFormControls]="[joinFrm.controls.address2]"
          [errorText]="'VALID.required' | translate"
        >
          <input
            fxFlex="1 1 0"
            id="address2"
            class="font-body"
            type="text"
            formControlName="address2"
            placeholder=""
          />
        </app-input-wrapper>
      </div>

      <div class="additional" ngClass.lt-sm="mobile">
        <div class="additional-head" ngClass.lt-sm="mobile">
          <img
            class="radio-icon"
            src="/assets/img/radio-icon-{{
              servicePolicyFl && privacyPolicyFl ? 'on' : 'off'
            }}.png"
            (click)="onClickAllAgree()"
          />
          <label class="radio-text font-body" (click)="onClickAllAgree()">{{
            'allAgree' | translate
          }}</label>
        </div>

        <div class="additional-body">
          <div class="service-policy">
            <div
              class="policy-head"
              [ngClass]="{ open: serviceOpenFl }"
              [ngClass.lt-sm]="{ open: serviceOpenFl, mobile: true }"
              (click)="onClickPolicyArrow('service', $event)"
            >
              <img
                class="radio-icon"
                src="/assets/img/radio-icon-{{
                  servicePolicyFl ? 'on' : 'off'
                }}.png"
                (click)="servicePolicyFl = !servicePolicyFl"
              />
              <label
                class="radio-text font-body"
                (click)="servicePolicyFl = !servicePolicyFl"
                >{{ 'newGloriaGroupServicePolicy' | translate }}</label
              >
            </div>
            <div
              class="policy-body"
              ngClass.lt-sm="mobile"
              *ngIf="serviceOpenFl"
            >
              <p class="policy-text font-caption">
                {{ 'privacyPolicyDesc' | translate }}
              </p>
            </div>
          </div>
          <div class="privacy-policy">
            <div
              class="policy-head"
              [ngClass]="{ open: privacyOpenFl }"
              [ngClass.lt-sm]="{ open: privacyOpenFl, mobile: true }"
              (click)="onClickPolicyArrow('privacy', $event)"
            >
              <img
                class="radio-icon"
                src="/assets/img/radio-icon-{{
                  privacyPolicyFl ? 'on' : 'off'
                }}.png"
                (click)="privacyPolicyFl = !privacyPolicyFl"
              />
              <label
                class="radio-text font-body"
                (click)="privacyPolicyFl = !privacyPolicyFl"
                >{{ 'privacyPolicy' | translate }}</label
              >
            </div>
            <div
              class="policy-body privacy"
              ngClass.lt-sm="mobile"
              *ngIf="privacyOpenFl"
            >
              <div class="policy-text font-caption">
                <!-- {{ 'privacyPolicyDesc' | translate }} -->
                <div class="container">
                  <div class="text" ngClass.lt-sm="mobile">
                    {{ 'privacyPolicyDesc1' | translate }}<br /><br />
                    {{ 'privacyPolicyDesc2' | translate }}<br /><br />
                    <div class="in">
                      {{ 'privacyPolicyDesc3' | translate }}<br />
                      {{ 'privacyPolicyDesc4' | translate }}<br />
                      {{ 'privacyPolicyDesc5' | translate }}<br />
                      {{ 'privacyPolicyDesc6' | translate }}<br />
                    </div>
                    <br />
                    {{ 'privacyPolicyDesc7' | translate }}<br />
                    {{ 'privacyPolicyDesc8' | translate }}<br />
                    {{ 'privacyPolicyDesc9' | translate }}<br />
                    {{ 'privacyPolicyDesc10' | translate }}<br />
                    {{ 'privacyPolicyDesc11' | translate }}<br />
                    {{ 'privacyPolicyDesc12' | translate }}<br /><br />
                    <div class="sub-tilte">
                      {{ 'privacyPolicyDesc13' | translate }}
                    </div>
                    {{ 'privacyPolicyDesc14' | translate }} <br /><br />
                    <div class="sub-tilte">
                      {{ 'privacyPolicyDesc15' | translate }}
                    </div>
                    {{ 'privacyPolicyDesc16' | translate }}<br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="error-text-box"
          *ngIf="!(privacyPolicyFl && servicePolicyFl) && policyErrorShowFl"
        >
          <p class="error-text">
            {{ 'pleaseAgreeNewGloriaPolicy' | translate }}
          </p>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="10px"
        class="btn-group"
        ngClass.lt-sm="mobile"
      >
        <button id="cancelBtn" type="button" ngClass.lt-sm="mobile">
          {{ 'cancel' | translate }}
        </button>
        <button
          id="joinBtn"
          ngClass.lt-sm="mobile"
          type="button"
          [pwCoachMark]="{ text: 'GUIDE.click' | translate }"
          [disabled]="isLoading"
          (click)="onClickJoin()"
        >
          {{ 'join' | translate }}
        </button>
      </div>
    </div>
  </app-gloria-content-section>
</ng-container>
