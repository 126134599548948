import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageRepositoryService } from '../abstract-repository.service';
import { ICpn, cpnEqualsFields } from './cpn.model';

@Injectable({
  providedIn: 'root',
})
export class CpnService extends PageRepositoryService<ICpn> {
  baseUri = `api/cpn`;

  equalsFields: string[] = cpnEqualsFields;

  /* extFixedParams */
  /* /extFixedParams */

  constructor(protected http: HttpClient) {
    super(http);
  }
}
