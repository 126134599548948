import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { DialogService } from '../components/dialog/dialog.service';
import { CustomHttpParameterCodec } from '../core/custom-http-parameter-codec';

/**
 * 야후 우편번호 api 응답
 *
 * 상세 타입은 이하 링크에서 확인
 */
export interface YahooZipCodeResponse {
  ResultInfo: any;
  Feature: any[];
}

/**
 * 야후 우편번호 검색 api
 *
 * 요청, 응답 타입 등 하기에서 확인할것
 *
 * @see https://developer.yahoo.co.jp
 * @see https://developer.yahoo.co.jp/webapi/map/openlocalplatform/v1/zipcodesearch.html
 *
 * ID: paywith.co@gmail.com
 */
@Injectable({
  providedIn: 'root',
})
export class YahooZipCodeApi {
  private readonly apiUrl =
    'https://map.yahooapis.jp/search/zip/V1/zipCodeSearch';

  private readonly appId =
    'dj00aiZpPW5uNmVGQnBkRHJmNyZzPWNvbnN1bWVyc2VjcmV0Jng9MzE-';

  constructor(
    private httpClient: HttpClient,
    private dialogService: DialogService
  ) {}

  /**
   * Yahoo 우편번호 검색 결과 반환
   */
  getAddressByZipCode(zipCode: string): Observable<YahooZipCodeResponse> {
    // 원본 파라미터
    const fromObject: any = {
      appid: this.appId,
      query: zipCode,
      output: 'json',
      // 이하 jsonp의 두번째 인자가 대신함
      // callback: 'JSONP_CALLBACK',
    };
    // 인코딩된 파라미터
    const httpParams = new HttpParams({
      // 앵귤러 인코딩 버그 수정용
      encoder: new CustomHttpParameterCodec(),
      fromObject,
    });

    // 해당 api는 cors를 지원하지 않으므로 jsonp 호출
    return this.httpClient
      .jsonp(
        `${this.apiUrl}?${httpParams.toString()}`,
        // 변경시 스크립트 태그 동적 생성과 콜백을 직접 처리해 함
        'callback'
      )
      .pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => {
          const { status } = httpErrorResponse;

          // TODO: 공통 처리 필요
          // 인터넷 연결 없을때
          if (status === 0 || status === 504) {
            return throwError(() => new Error('networkConnectionError'));
          }

          return throwError(() => httpErrorResponse);
        })
      ) as any;
  }
}
