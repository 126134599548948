import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { camelCase } from 'lodash-es';
import {
  BehaviorSubject,
  MonoTypeOperatorFunction,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { delay, map, mergeMap, retryWhen, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IHalPageResponse, IPage, IPageResponse } from './page.model';

/**
 * API 서버 통신 처리 공통화.
 *
 * T : Entity 타입
 * T2 : API 응답 형태(HAL, Spring)
 */
export abstract class PageRepositoryService<
  T,
  T2 extends IHalPageResponse | IPageResponse = IHalPageResponse
> {
  /**
   * 서버 URL
   * @example `environment.serverUrl`
   */
  protected apiServerUrl = environment.apiServerUrl;

  /**
   * 엔드포인트
   * @example 'oauth/token'
   */
  protected baseUri = '';

  /** api 엔티티(T)의 기본키 */
  protected pk = 'id';

  /** 두 객체의 값이 같은지 비교할 때 사용할 필드 */
  equalsFields = ['id'];

  /** parent.id 등 검색조건의 . 을 유지할지 아니면 camelize 할지 */
  protected searchParamKeyCamelize =
    environment.searchParamKeyCamelize ?? false;

  /** 통신 상태 관리 */
  _isLoading = false;

  /** 통신 상태 관리 subject */
  isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    this._isLoading
  );

  /** 통신 상태 */
  get isLoading$(): Observable<boolean> {
    return this.isLoadingSubject.asObservable();
  }

  // 조회 상태 유지 위한 변수
  private _list!: IPage<T>;

  private _options: Array<T> = null;

  private _listSubject: BehaviorSubject<IPage<T>> = new BehaviorSubject(
    this._list
  );

  private _optionsSubject: BehaviorSubject<Array<T>> = new BehaviorSubject(
    this._options
  );

  /**
   * 현재 검색조건
   */
  recentSearchQuery: any = {};

  /**
   * 현재 조건 해당 목록
   *
   * getPage(단 건), appendPage(누적) 결과
   */
  get list$(): Observable<IPage<T>> {
    return this._listSubject.asObservable();
  }

  /**
   * 옵션 선택을 위한 전체 목록
   *
   * TODO: 최적화 필요
   */
  get options$(): Observable<Array<T>> {
    return this._optionsSubject.asObservable();
  }

  // /조회 상태 유지 위한 변수

  constructor(protected http: HttpClient) {
    this.initList();
  }

  /**
   * 두 객체가 동일한지 확인. 기본적으로 id 필드가 동일한지 확인하지만 변경할 수 있다.
   */
  equalsFn(obj1: T, obj2: T): boolean {
    const equalsFields = this.equalsFields ?? [this.pk];
    return obj1 && obj2 && equalsFields.every((key) => obj1[key] === obj2[key]);
  }

  create(data: T): Observable<T> {
    this.isLoadingSubject.next(true);
    return this.http.post<T>(`${this.apiServerUrl}/${this.baseUri}`, data).pipe(
      tap(() => {
        this.isLoadingSubject.next(false);
      }),
      this.handleError()
    );
  }

  /**
   * 검색조건에 일치하는 목록 조회 api 호출하여 페이지 데이터 조회
   *
   * getPage 와 기능 다음에 유의
   */
  findPage(params: any = {}, body: any = {}): Observable<IPage<T>> {
    const httpParams = this.makeObjToHttpParams(params);

    return this.http
      .get<T2>(`${this.apiServerUrl}/${this.baseUri}`, {
        params: httpParams,
      })
      .pipe(
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
        map((res) => this._parsePage(res)),
        this.handleError()
      );
  }

  /**
   * 페이지를 요청하고, 현재 목록에 페이지를 추가 한다.
   *
   * TODO: 전페이지 조회, 현재 페이지 조회 중간에 추가된 아이템이 있다면 중복 처리 해야 함
   */
  appendPage(query = this.recentSearchQuery): void {
    this.isLoadingSubject.next(true);

    this.findPage(query).subscribe(
      (res) => {
        // 성공했다면 최근 조건으로 set
        this.recentSearchQuery = query;

        // TODO: 중복 처리 필요
        this._list.content.push(...res.content);
        this._list.page = res.page;

        this._listSubject.next(this._list);
      },
      (error) => {
        this.initList();
        this._listSubject.error(error);
      }
    );
  }

  /**
   * 조회 조건에 맞는 한개의 아이템 조회.
   * 0개거나 복수개라면 에러
   * 1개일 경우에만 상세 조회 후 return
   */
  findOne(params: any = {}, body: any = {}): Observable<T> {
    const httpParams = this.makeObjToHttpParams(params);

    return this.http
      .get<T2>(`${this.apiServerUrl}/${this.baseUri}`, {
        params: httpParams,
      })
      .pipe(
        map((res) => this._parsePage(res)),
        this.handleError(),
        mergeMap((page) => {
          // 조회 개수가 한개가 아니면 에러
          if (page.page.totalElements !== 1) {
            return throwError(new Error('NODATA'));
          }

          // 한개인경우 첫번째 아이템 상세 조회
          return this.findItem(page.content[0][this.pk]);
        })
      );
  }

  findItem(id: number): Observable<T> {
    this.isLoadingSubject.next(true);
    return this.http.get<T>(`${this.apiServerUrl}/${this.baseUri}/${id}`).pipe(
      tap(() => {
        this.isLoadingSubject.next(false);
      }),
      this.handleError()
    );
  }

  update(id: any, item: T): Observable<T> {
    this.isLoadingSubject.next(true);
    return this.http
      .put<T>(`${this.apiServerUrl}/${this.baseUri}/${id}`, item)
      .pipe(
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
        this.handleError()
      );
  }

  delete(id: number): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.delete(`${this.apiServerUrl}/${this.baseUri}/${id}`).pipe(
      tap(() => {
        this.isLoadingSubject.next(false);
      }),
      this.handleError()
    );
  }

  /**
   * 조건에 해당하는 페이지를 요청한다
   */
  getPage(query = this.recentSearchQuery): void {
    this.isLoadingSubject.next(true);

    this.findPage(query).subscribe(
      (res) => {
        // 성공했다면 최근 조건으로 set
        this.recentSearchQuery = query;

        this._list.content = res.content;
        this._list.page = res.page;

        this._listSubject.next(this._list);
      },
      (error) => {
        this.initList();
        this._listSubject.error(error);
      }
    );
  }

  getOptions(forceRefresh = true, params = {}): void {
    let httpParams = this.makeObjToHttpParams(params);
    httpParams = httpParams.set('size', 1000);

    if (forceRefresh) {
      this.http
        .get<T2>(`${this.apiServerUrl}/${this.baseUri}`, {
          params: httpParams,
        })
        .subscribe(
          (res) => {
            this._options = this._parsePage(res).content;
            this._optionsSubject.next(this._options);
          },
          (error) => {
            delete this._options;
            this._optionsSubject.error(error);
          }
        );
    }
  }

  /**
   * 현재 목록 초기화
   *
   * 최초 사용, 요청 오류 시 등에 사용
   */
  initList(): void {
    this._list = {
      content: [],
      page: null,
    };

    this._listSubject.next(this._list);
  }

  /**
   * 호출받았을 때 객체를 http요청에 사용하도록 타입 변경
   *
   * 빈 값 삭제하고, 서버 타입에 맞춰 .(dot)을 유지할지 camelize를 시킬지 결정한다
   *
   * @deprecated 이하 6통상에서 가져온 코드이나 더이상 사용하지 않는 로직이므로 삭제 예정
   */
  protected makeObjToHttpParams(obj: any): HttpParams {
    let httpParams = new HttpParams();

    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null && obj[key] !== undefined) {
        if (this.searchParamKeyCamelize) {
          if (Array.isArray(obj[key])) {
            obj[key].forEach((item) => {
              httpParams = httpParams.append(camelCase(key), item);
            });
          } else {
            httpParams = httpParams.set(camelCase(key), obj[key]);
          }
        } else if (Array.isArray(obj[key])) {
          obj[key].forEach((item) => {
            httpParams = httpParams.append(key, item);
          });
        } else {
          httpParams = httpParams.set(key, obj[key]);
        }
      }
    });

    return httpParams;
  }

  /**
   * 서버에서 응답받은 페이지 형식을 클라이언트 처리 용이하게 변경
   */
  protected _parsePage(serverResponse: T2): IPage<T> {
    if (!('page' in serverResponse)) {
      // T2의 형식이 IPageResponse 이면
      const { content, size, totalElements, totalPages, number } =
        serverResponse as IPageResponse;

      return {
        content,
        page: {
          size,
          totalElements,
          totalPages,
          number,
        },
      };
    }

    // content 가 없으면 T2의 형식이 IHalPageResponse 로 간주
    const { _links, _embedded, content, page } =
      serverResponse as IHalPageResponse;

    return {
      content:
        (_embedded && _embedded[Object.keys(_embedded)[0]]) ?? content ?? [],
      page,
    };
  }

  /**
   * 통신 오류 처리
   * 401 제외 4XX 통신 오류는 3번 재시도
   */
  handleError<R>(retryCount = 3): MonoTypeOperatorFunction<R> {
    let count = 0;
    return retryWhen((e) => {
      return e.pipe(
        mergeMap((v) => {
          if (count >= retryCount) {
            return throwError(new Error(this._getErrorMessages(v)));
          }

          // 400번대 오류면
          if (v.status >= 400 && v.status <= 499) {
            count += 1;
            return of(v);
          }

          // 인터넷 연결 없을때
          if (v.status === 0 || v.status === 504) {
            count += 1;
            // 1초마다 재시도
            return of(v).pipe(delay(1000));
          }

          return throwError(new Error(this._getErrorMessages(v)));
        })
      );
    });
  }

  /**
   * 내트워크 오류 메시지 가공
   */
  private _getErrorMessages({ status, error }: HttpErrorResponse): string {
    if (error?.errors) {
      const e: {
        code: string;
        errors: { [key: string]: string }[];
        message: string;
      } = error;
      let message = `${e.message}[${e.code}]`;
      e.errors.forEach((err) => {
        Object.entries(err).forEach(([key, value]) => {
          message += `\n${key} : ${value}`;
        });
      });
      return message;
    }

    /** 뉴글로리아 api 전용 오류 처리 */
    if (error?.Result) {
      const e: {
        Code: string;
        Status: string;
        Message: string;
      } = error?.Result;
      return `${e.Message}[${e.Code}]`;
    }

    // 인터넷 연결 없을때
    if (status === 0 || status === 504) {
      return 'networkConnectionError';
    }

    if (status === 400) {
      // api 서버에서 badRequest 응답을 받았을 때. 예로 현 golftour 프로젝트에서 ExceptionHandler 없이 error 를 응답하는 경우 등
      const e: {
        content;
      } = error;
      // if (e.content) {
      //   const m = e.content
      //     .map((err) => `${err.objectName} : ${err.defaultMessage}`)
      //     .join('\n');
      //   return `${m}`;
      // }
      if (e.content?.length) {
        return e.content[0].defaultMessage;
        // const m = e.content
        //   .map((err) => `${err.objectName} : ${err.defaultMessage}`)
        //   .join('\n');
        // return `${m}`;
      }
    }

    if (status === 500) {
      const e: {
        status: number;
        error: string;
        message: string;
      } = error;

      return e?.message || `${e.error}[${e.status}]`;
    }

    return `Network Error(${status})`;
  }
}
