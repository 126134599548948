import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { camelCase } from 'lodash-es';
import { Observable } from 'rxjs';
import { AlertComponent } from './alert/alert.component';
import { ConfirmLoginComponent } from './confirm-login/confirm-login.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { EmailNotReceivingNotificationComponent } from './email-not-receiving-notification/email-not-receiving-notification.component';
import {
  SearchZipCodeComponent,
  ZipCodeSearchResult,
} from './search-zip-code/search-zip-code.component';

const matDialogConfig: MatDialogConfig<any> = {
  width: '80vw',
  // height: '160px',
  panelClass: 'custom-mat-dialog',
  hasBackdrop: true,
  backdropClass: 'custom-mat-dialog-backdrop',
};

/**
 * 머터리얼 컴포넌트를 이용한 대화창 서비스
 * @see `DialogModule`
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private scrollStrategy: ScrollStrategy;

  constructor(
    public matDialog: MatDialog,
    private scrollStrategyOptions: ScrollStrategyOptions,
    private translateService: TranslateService
  ) {
    this.scrollStrategy = this.scrollStrategyOptions.block();
  }

  /**
   * 확인 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param config data 제외한 설정 내용
   */
  alert(
    body: string,
    header?: string,
    config?: MatDialogConfig<void>
  ): Observable<any> {
    const dialogRef = this.matDialog.open(AlertComponent, {
      ...matDialogConfig,
      ...config,
      panelClass: 'popup',
      data: {
        body,
        header,
      },
    });
    return dialogRef.afterClosed();
  }

  /**
   * 로그인/회원가입 창
   * @param nextPath 로그인/회원가입 이후 이동할 경로,
   * @param config data 제외한 설정 내용
   */
  confirmLogin(config?: MatDialogConfig<void>): Observable<any> {
    const dialogRef = this.matDialog.open(ConfirmLoginComponent, {
      ...matDialogConfig,
      ...config,
      panelClass: 'popup-confirm',
    });
    return dialogRef //
      .afterClosed();
  }

  /**
   * 예/아니오 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param config data 제외한 설정 내용
   * @param confirmText 확인 버튼 내용
   */
  confirm(
    body: string,
    header?: string,
    config?: MatDialogConfig<void>,
    confirmText: { check: string; cancel: string } = {
      check: 'check',
      cancel: 'cancel',
    }
  ): Observable<any> {
    // if (!header) {
    //   header = '안내';
    // }
    const dialogRef = this.matDialog.open(ConfirmComponent, {
      ...matDialogConfig,
      ...config,
      panelClass: 'popup',
      data: {
        body,
        header,
        confirmText,
      },
    });
    return dialogRef //
      .afterClosed();
  }

  /**
   * 우편번호 검색
   * @param body 본문 내용
   * @param header 상단 내용
   * @param config data 제외한 설정 내용
   */
  searchZipCode(
    body?: string,
    header?: string,
    config?: MatDialogConfig<void>
  ): Observable<ZipCodeSearchResult> {
    const dialogRef = this.matDialog.open(SearchZipCodeComponent, {
      ...config,
      panelClass: 'zipcode-panel',
      hasBackdrop: true,
      // backdropClass: 'custom-mat-dialog-backdrop',
      maxWidth: 'auto',
      maxHeight: 'auto',
      scrollStrategy: this.scrollStrategy,
    });

    return dialogRef.afterClosed();
  }

  /**
   * 이메일이 도착하지 않은 경우 안내
   * @param config data 제외한 설정 내용
   */
  openEmailNotReceivingNotification(
    config?: MatDialogConfig<void>
  ): Observable<void> {
    const dialogRef = this.matDialog.open(
      EmailNotReceivingNotificationComponent,
      {
        ...config,
        panelClass: 'zipcode-panel',
        hasBackdrop: true,
        maxWidth: 'auto',
        maxHeight: 'auto',
        scrollStrategy: this.scrollStrategy,
      }
    );

    return dialogRef.afterClosed();
  }

  /**
   * 오류 표시 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param config data 제외한 설정 내용
   */
  openErrorAlert(
    error: string,
    message?: string,
    config?: MatDialogConfig<void>
  ): Observable<any> {
    // if (!header) {
    //   header = '안내';
    // }
    const dialogRef = this.matDialog.open(AlertComponent, {
      ...matDialogConfig,
      ...config,
      panelClass: 'popup',
      data: {
        body: `${this.translateService.instant(camelCase(error))}\n${message}`,
      },
    });
    return dialogRef //
      .afterClosed();
  }

  /**
   * 사용자 지정 팝업 오픈
   * @param component 표시할 컴포넌트
   * @param params 옵션 객체 (data, dialogRef, config)
   * @param data 추가 데이터
   * @param dialogRef 기존 Dialog 참조, 있으면 기존 Dialog 닫음
   * @param config data 제외한 설정 내용
   */
  openComponent(
    component: ComponentType<any>,
    params?: { data?: any; dialogRef?: any; config?: MatDialogConfig<void> }
  ): Observable<any> {
    if (!params?.config) {
      // config = {
      // height: '600px',
      // width: '400px',
      // };
    }
    if (params?.dialogRef) {
      params?.dialogRef.close();
    }

    return this.matDialog
      .open(component, {
        ...matDialogConfig,
        ...params?.config,
        panelClass: 'no-shadow-panel',
        data: params?.data,
      })
      .afterClosed();
  }

  /**
   * Opens a snackbar with a message and an optional action.
   * @param message The message to show in the snackbar.
   * @param action The label for the snackbar action.
   * @param config Additional configuration options for the snackbar.
   */
  // snackbar(
  //   message: string,
  //   action: string = null,
  //   config: MatSnackBarConfig = { duration: 2000 }
  // ): void {
  //   this._snackBar.open(message, action, config);
  // }
}
