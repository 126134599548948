<div class="popup">
  <span class="header">{{ data?.header }}</span>

  <div class="body">{{ data?.body }}</div>
</div>

<div class="popUp_btn">
  <button mat-ripple class="yes_btn" matRippleColor="#fff3" (click)="onYes()">
    {{ data.confirmText.check | translate }}
  </button>
  <button mat-ripple class="no_btn" (click)="onNo()">
    {{ data.confirmText.cancel | translate }}
  </button>
</div>
