import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import {
  MissingTranslationHandler,
  TranslateModule,
} from '@ngx-translate/core';
import { PwCoachMarkModule, PwImgModule, PwRubyModule } from 'pw-lib';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PwMissingTranslationHandler } from '../../services/pw-missing-translation-handler.service';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
  imports: [
    CommonModule,
    MatRippleModule,
    FlexLayoutModule,
    LoadingModule,
    TranslateModule.forChild({
      useDefaultLang: false,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: PwMissingTranslationHandler,
      },
    }),
    PwCoachMarkModule,
    PwImgModule,
    PwRubyModule,
    DirectivesModule,
  ],
  exports: [
    MatRippleModule,
    MatIconModule,
    FlexLayoutModule,
    LoadingModule,
    PwCoachMarkModule,
    PwImgModule,
    PwRubyModule,
    PipesModule,
    DirectivesModule,
  ],
})
export class SharedModule {}
