<main wrapper>
  <div class="close-wrap">
    <button mat-icon-button class="close-button" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayoutAlign="center">
    <img
      class="login-lock-img"
      src="/assets/img/login-lock.png"
      srcset="
        /assets/img/login-lock@2x.png 2x,
        /assets/img/login-lock@3x.png 3x
      "
    />
  </div>

  <form
    [formGroup]="loginForm"
    class="login-container"
    (submit)="onLoginClick()"
  >
    <mat-form-field color="accent">
      <mat-label> {{ 'emailAsId' | translate }} </mat-label>
      <input
        matInput
        type="text"
        autocomplete="username"
        formControlName="id"
      />
    </mat-form-field>
    <mat-form-field color="accent">
      <mat-label>{{ 'pw' | translate }}</mat-label>
      <input
        matInput
        [type]="isPasswordVisible ? 'text' : 'password'"
        autocomplete="current-password"
        formControlName="pw"
      />
      <button
        type="button"
        class="pw-visibility"
        mat-icon-button
        matSuffix
        (click)="isPasswordVisible = !isPasswordVisible"
      >
        <mat-icon>{{
          isPasswordVisible ? 'visibility' : 'visibility_off'
        }}</mat-icon>
      </button>
    </mat-form-field>
    <div class="option-wrapper">
      <a (click)="onFindPwClick()">
        {{ 'findPw' | translate }}
      </a>
    </div>

    <div
      class="button-wrapper"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="8px"
    >
      <button mat-flat-button round color="accent">
        {{ 'login' | translate }}
      </button>
    </div>
  </form>
</main>
