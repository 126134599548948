import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/**
 * 정보입력 컴포넌트
 * invalid 메세지 처리
 */
@Component({
  selector: 'app-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss'],
})
export class InputWrapperComponent implements OnInit {
  @Input() inputFormControls: AbstractControl[];

  /** 오류 문구 */
  @Input() errorText: string;

  /** 안내사항 문구 */
  @Input() noticeText: string;

  /** 입력 항목이름 */
  @Input() inputTitle: string;

  /** 입력 필수 여부 */
  @Input() requiredFl: boolean;

  /** input 아이템 간 간격 */
  @Input() gap = '0px';

  constructor() {}

  ngOnInit(): void {}

  /**
   * 유효성 검사
   */
  isInValid(): boolean {
    // 컨트롤 없으면
    if (this.inputFormControls == null) {
      // 유효
      return false;
    }

    // 검사
    const result = this.inputFormControls.some((form) => {
      // disabled 일때 유효성과 관계 없이 valid = false 이므로 주의
      return form.invalid && (form.dirty || form.touched);
    });

    return result;
  }
}
