<div class="tab-spacer" fxHide="true" fxShow.lt-sm="true"></div>
<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="tab-group"
  fxHide="true"
  fxShow.lt-sm="true"
>
  <a
    class="tab"
    fxLayout="column"
    fxLayoutGap="6px"
    fxLayoutAlign="center center"
    routerLink="/"
    role="link"
    matRipple
  >
    <img
      class="tab-icon"
      src="/assets/img/tab/home-icon{{
        isCurrentUrl('/home') ? '-on' : ''
      }}.png"
    />
    <span
      class="tab-title font-caption"
      [ngClass]="{ checked: isCurrentUrl('/home') }"
      >{{ 'home' | translate }}</span
    >
  </a>
  <a
    class="tab"
    fxLayout="column"
    fxLayoutGap="6px"
    fxLayoutAlign="center center"
    routerLink="/rsrv/facility"
    role="link"
    matRipple
  >
    <img
      class="tab-icon"
      src="/assets/img/tab/rsrv-icon{{
        isCurrentUrl('/rsrv/facility') ? '-on' : ''
      }}.png"
    />
    <span
      class="tab-title font-caption"
      [ngClass]="{ checked: isCurrentUrl('/rsrv/facility') }"
      >{{ 'rsrv' | translate }}</span
    >
  </a>

  <!-- 2024-07-24 부터 회원 관련 기능 중지 -->
  <!-- <a
    class="tab"
    fxLayout="column"
    fxLayoutGap="6px"
    fxLayoutAlign="center center"
    routerLink="/my/fav"
    role="link"
    matRipple
  >
    <img
      class="tab-icon"
      src="/assets/img/tab/favorite-icon{{
        isCurrentUrl('/my/fav') ? '-on' : ''
      }}.png"
    />
    <span
      class="tab-title font-caption"
      [ngClass]="{ checked: isCurrentUrl('/my/fav') }"
      >{{ 'fav' | translate }}</span
    >
  </a>
  <a
    class="tab"
    fxLayout="column"
    fxLayoutGap="6px"
    fxLayoutAlign="center center"
    routerLink="/my/rsrv"
    role="link"
    matRipple
  >
    <img
      class="tab-icon"
      src="/assets/img/tab/rsrv-list-icon{{
        isCurrentUrl('/my/rsrv') ? '-on' : ''
      }}.png"
    />
    <span
      class="tab-title font-caption"
      [ngClass]="{ checked: isCurrentUrl('/my/rsrv') }"
      >{{ 'rsrvHist' | translate }}</span
    >
  </a>
  <a
    class="tab"
    fxLayout="column"
    fxLayoutGap="6px"
    fxLayoutAlign="center center"
    routerLink="/mobile-my"
    role="link"
    matRipple
  >
    <img
      class="tab-icon"
      src="/assets/img/tab/my-icon{{
        isCurrentUrl('/mobile-my') ? '-on' : ''
      }}.png"
    />
    <span
      class="tab-title font-caption"
      [ngClass]="{ checked: isCurrentUrl('/mobile-my') }"
      >MY</span
    >
  </a> -->
</div>
