<div class="dialog-wrapper">
  <header class="dialog-header">
    <h2 class="dialog-title">
      {{ 'email.notReceivingNotification' | translate }}
    </h2>

    <button mat-icon-button class="dialog-close" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </header>

  <!-- 자체 번역 불필요 -->
  <main class="dialog-main">
    <table>
      <tr>
        <th>こちらをご確認ください</th>
      </tr>

      <tr>
        <td>迷惑メールフォルターの確認</td>
      </tr>

      <tr>
        <td>
          「My DoCoMo」
          <br />
          「My Softbank」
          <br />
          「My au」などのキャリアサイトから画面に従って
          <br />
          「@gloria-g.com」のドメインを受信許可に設定し再度メール送信をする
        </td>
      </tr>

      <tr>
        <td>アドレスに誤りが無いか確認のうえ再度入力</td>
      </tr>
    </table>
  </main>
</div>
