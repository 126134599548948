import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { DialogService } from '../components/dialog/dialog.service';
import { LoginComponent } from '../components/dialog/login/login.component';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree>
    | UrlTree
    | boolean {
    if (
      this.authService.loginInfo &&
      this.authService.loginInfo.certType !== 'GUEST'
    ) {
      return true;
    }

    return this.authService.getNewAuth().pipe(
      mergeMap((token) => {
        if (token) {
          return of(true);
        }

        return this.dialogService.matDialog
          .open(LoginComponent, {
            maxWidth: '100vw',
          })
          .beforeClosed();
      }),
      catchError(() => {
        return of(this.router.parseUrl('/'));
      })
    );
  }
}
