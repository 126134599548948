<div
  [ngStyle]="{
    'background-image': 'url(' + bgImg + ')',
    'background-color': bgColor,
    height: sectionRatio,
    margin: margin,
    'min-height': minHeight,
    'max-height': maxHeight,
    'border-bottom': borderBottom
  }"
>
  <div class="content" ngClass.lt-sm="mobile" [ngStyle]="{ padding: padding }">
    <ng-content></ng-content>
  </div>
</div>
