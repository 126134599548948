import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { ExtPageRepositoryService } from '../abstract-ext-repository.service';
import { IMrhst } from '../mrhst/mrhst.model';
import { IPage } from '../page.model';

@Injectable({
  providedIn: 'root',
})
export class TopFacilityService extends ExtPageRepositoryService<IMrhst> {
  baseUri = `api/mrhst`;

  recentSearchQuery: any = {
    dpLocList: 'TOP2',
    mrhstType: 'HOTEL',
    sort: 'sort,asc',
  };

  constructor(protected http: HttpClient) {
    super(http);
  }

  override findPage(params?: any, body?: any): Observable<IPage<IMrhst>> {
    const httpParams = this.makeObjToHttpParams(params);

    return this.http
      .get(`${this.apiServerUrl}/${this.baseUri}/gloria`, {
        params: httpParams,
      })
      .pipe(
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
        map((res: any) => this._parsePage(res)),
        this.handleError()
      );
  }
}
