<div>
  <label class="input-title font-body" *ngIf="inputTitle"
    >{{ inputTitle
    }}<span *ngIf="requiredFl" class="required-mark font-body">*</span></label
  >
  <div fxLayout="row" [fxLayoutGap]="gap">
    <ng-content></ng-content>
  </div>
  <div class="error-text-box" *ngIf="isInValid() && errorText">
    <p class="error-text" ngClass.lt-sm="mobile">{{ errorText }}</p>
  </div>

  <div class="notice-text font-caption" *ngIf="noticeText">
    ※ {{ 'mainContactHint' | translate }}
  </div>
</div>
