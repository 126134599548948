import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { GloriaFooterComponent } from './gloria-footer.component';

@NgModule({
  declarations: [GloriaFooterComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    RouterModule,
    PipesModule,
  ],
  exports: [GloriaFooterComponent],
})
export class GloriaFooterModule {}
