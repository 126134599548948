import { HttpParameterCodec } from '@angular/common/http';

/**
 * 앵귤러 14 미만 버전에서 url의 "+"를 " "로 변환시켜버리는 버그가 있으므로 보완하는 클래스
 */
export class CustomHttpParameterCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
