<div class="input-group" [formGroup]="joinFrm">
  <div class="input-group-wrapper" fxLayout="column" fxLayoutGap="17px">
    <app-input-wrapper
      [inputTitle]="'email' | translate"
      [requiredFl]="true"
      [inputFormControls]="[joinFrm.controls.email]"
      [errorText]="'VALID.email' | translate"
    >
      <div
        class="submit-wrapper"
        fxLayout.lt-sm="column"
        ngClass.lt-sm="mobile"
      >
        <input
          id="email"
          class="font-body"
          fxFlex="1 1 0"
          type="email"
          formControlName="email"
          [disabled]="certId"
          [class.disabled]="isSubmitEmail"
          (keyup)="onEmailKeyup($event)"
          placeholder="{{ 'email' | translate }}"
        />
        <button
          class="btn-outline-blue"
          [class.active]="joinFrm.controls.email.valid && !isSubmitEmail"
          (click)="submitEmail()"
          [disabled]="!joinFrm.controls.email.valid || isSubmitEmail"
        >
          <div>
            {{ 'validateEmail' | translate }}
          </div>
          <span class="remain-time" *ngIf="remainTime > 0">
            {{ remainTime | date: 'm:ss' }}
          </span>
        </button>
      </div>
    </app-input-wrapper>
    <div [formGroup]="authenticationFrm">
      <app-input-wrapper
        [inputTitle]="'certNum' | translate"
        [requiredFl]="true"
        [inputFormControls]="[authenticationFrm.controls.authenticationNum]"
        [errorText]="'wrongCert' | translate"
      >
        <div
          class="submit-wrapper"
          fxLayout.lt-sm="column"
          ngClass.lt-sm="mobile"
        >
          <input
            #authenticationNum
            id="authenticationNum"
            class="font-body"
            fxFlex="1 1 0"
            type="authenticationNum"
            formControlName="authenticationNum"
            placeholder="{{ 'inputCertNumNDigit' | translate: { n: 6 } }}"
            (keyup)="onCertKeyup($event)"
          />
          <button
            class="btn-outline-blue"
            [class.active]="certId"
            (click)="submitCert()"
          >
            {{ 'confirm' | translate }}
          </button>
        </div>
      </app-input-wrapper>
    </div>

    <!-- 이메일 안내 -->
    <div class="email-notification" (click)="onEmailNotificationClick()">
      <span>{{ 'email.notReceivingNotification' | translate }}</span>
      <mat-icon class="open-in-new-icon">open_in_new</mat-icon>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayoutGap="10px"
    class="btn-group"
    ngClass.lt-sm="mobile"
  >
    <button
      id="cancelBtn"
      type="button"
      ngClass.lt-sm="mobile"
      (click)="onCancelClick()"
    >
      {{ 'cancel' | translate }}
    </button>
    <!-- <button
      id="joinBtn"
      ngClass.lt-sm="mobile"
      type="button"
      [disabled]="!certId"
      (click)="onClickNext.emit()"
    >
      {{ 'next' | translate }}
    </button> -->
  </div>
  <div class="loading-container" *ngIf="isSubmitLoading">
    <app-loading></app-loading>
  </div>
</div>
