import { ICpnMaster } from '../cpn-master/cpn-master.model';
import { CpnSttsType } from '../enumerations/cpn-stts-type.model';
import { ICpnIssu } from '../cpn-issu/cpn-issu.model';

/* extFixedValues */
/* /extFixedValues */

/** 두 객체가 같은지 확인할 때 사용할 필드 목록 */
/* model_equalsFields */ export const cpnEqualsFields = ['id'];
/* /model_equalsFields */

/**
 * 쿠폰 인터페이스
 */
export interface ICpn {
  /**
   * id
   */
  id?: number;
  /**
   * cpn_master
   */
  cpnMaster?: ICpnMaster;
  /**
   * cpn_no
   */
  cpnNo?: string;
  /**
   * cpn_stts_type
   */
  cpnSttsType?: CpnSttsType;
  /**
   * username
   */
  username?: string;
  /**
   * cpn_issu
   */
  cpnIssu?: ICpnIssu;
  /**
   * read_yn
   */
  readFl?: any;
}

/**
 * 쿠폰 클래스. 기본값 포함한 객체 생성
 */
export class Cpn implements ICpn {
  constructor(
    public id?: number,
    public cpnMaster?: ICpnMaster | null,
    public cpnNo?: string | null,
    public cpnSttsType?: CpnSttsType | null,
    public username?: string | null,
    public cpnIssu?: ICpnIssu | null,
    public readFl?: any | null
  ) {}
}
