import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputWrapperComponent } from './input-wrapper.component';

@NgModule({
  declarations: [InputWrapperComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    FormsModule,
  ],
  exports: [InputWrapperComponent],
})
export class InputWrapperModule {}
