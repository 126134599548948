<div fxHide.lt-sm="true"></div>
<article
  fxLayout="'row'"
  [fxLayoutAlign]="'center center'"
  ngStyle.lt-md="padding: 0 40px 0 0"
  fxHide.lt-sm="true"
>
  <nav class="menu-list" fxLayout="row nowrap" fxLayoutAlign="center center">
    <!-- <a
      class="menu-item home-icon icon-menu-item"
      routerLink="/home"
      fxHide.lt-md
      matRipple
      matRippleColor="warn"
      [pwCoachMark]="{ text: 'GUIDE.goHome' | translate }"
    >
      <img src="/assets/img/home-icon.png" />
    </a> -->
    <div class="menu-tabbar" fxFlex="1 1 auto">
      <div mat-tab-nav-bar [backgroundColor]="'accent'">
        <a
          mat-tab-link
          class="menu-item notranslate"
          routerLink="/home"
          routerLinkActive="on"
        >
          Home
        </a>
        <a
          mat-tab-link
          class="menu-item"
          routerLink="/facility"
          routerLinkActive="on"
        >
          {{ 'facilityList' | translate }}
        </a>
        <a mat-tab-link class="menu-item" routerLink="/video">
          {{ 'introMv' | translate }}
        </a>
        <a
          mat-tab-link
          class="menu-item"
          routerLink="/company"
          routerLinkActive="on"
        >
          {{ 'companyInfo' | translate }}
        </a>
        <a
          mat-tab-link
          class="menu-item"
          routerLink="/tabi"
          routerLinkActive="on"
        >
          {{ 'tabiClub' | translate }}
        </a>
        <a
          mat-tab-link
          class="menu-item"
          routerLink="/recruit"
          routerLinkActive="on"
        >
          {{ 'recruit' | translate }}
        </a>
        <!-- <a
          mat-tab-link
          class="menu-item"
          routerLink="/cs"
          routerLinkActive="on"
          [pwCoachMark]="{ text: 'csCenter' | translate }"
        >
          {{ 'csCenter' | translate }}
        </a> -->
        <!-- <a
          mat-tab-link
          class="menu-item"
          routerLink="/ordr"
          routerLinkActive="on"
          [pwCoachMark]="{ text: 'ordr' | translate }"
        >
          {{ 'ordr' | translate }}
        </a> -->
      </div>
    </div>
    <ng-container *ngIf="userInfo$ | async as userInfo">
      <ng-container *ngIf="userInfo?.certType !== 'GUEST'">
        <div fxLayoutAlign="center center" class="user-info-box">
          <span
            class="user-info notranslate"
            ngClass.lt-sm="mobile"
            *ngIf="userInfo?.userCard as userCard"
            >{{ userInfo?.userNm }} {{ 'sir' | translate }}
            {{ userCard.prpayAmt | number }}P</span
          >
          <button
            (click)="onClickRefreshInfo()"
            class="info-refresh-btn"
            ngClass.lt-sm="mobile"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </ng-container>
    </ng-container>
    <a class="menu-item rsrv-menu-item" routerLink="/rsrv/facility" role="link">
      {{ 'bookableMrhstList' | translate }}
    </a>
  </nav>
</article>
<!-- <article class="header-menu-container">
  <section class="header-menu-wrap">
    <div class="home-icon-wrap" routerLink="'/home'">
      <img class="home-icon" src="/assets/img/home-icon.png" />
    </div>
    <ul class="header-text-menu-wrap">
      <li routerLink="'/home'">{{ 'home' | translate }}</li>
      <li>{{ 'rsrv' | translate }}</li>
      <li>{{ 'introMv' | translate }}</li>
      <li>{{ 'tabiClub' | translate }}</li>
      <li>{{ 'csCenter' | translate }}</li>
      <li>my</li>
    </ul>
  </section>
</article> -->
