import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataI18nPipe } from './data-i18n.pipe';
import { LocalePipe } from './locale.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeResourceUrlPipe } from './safe-resource-url.pipe';
import { TmLocalePipe } from './tm-locale.pipe';
import { TranslateWithGooglePipe } from './translate-with-google.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    LocalePipe,
    TmLocalePipe,
    TranslateWithGooglePipe,
    DataI18nPipe,
  ],
  imports: [CommonModule],
  exports: [
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    LocalePipe,
    TmLocalePipe,
    TranslateWithGooglePipe,
    DataI18nPipe,
  ],
})
export class PipesModule {}
