import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  map,
  mergeMap,
  MonoTypeOperatorFunction,
  Observable,
  of,
  retryWhen,
  tap,
  throwError,
} from 'rxjs';
import { PageRepositoryService } from '../repository/abstract-repository.service';
import {
  IHalPageResponse,
  IPage,
  IPageResponse,
} from '../repository/page.model';

@Injectable({
  providedIn: 'root',
})
export class GloriaUserInfoService extends PageRepositoryService<any> {
  baseUri = `api/userInfo`;

  /* extFixedParams */
  /* /extFixedParams */

  constructor(protected http: HttpClient) {
    super(http);
  }

  getList(query: any): Observable<any> {
    return this._findPage2(query);
  }

  private _findPage2(params: any = {}, body: any = {}): Observable<IPage<any>> {
    return this.http
      .get<any>(`${this.apiServerUrl}/${this.baseUri}`, {
        params,
      })
      .pipe(
        map((res) => this._parsePage2(res)),
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
        this._handleError2()
      );
  }

  /**
   * 통신 오류 처리
   * 401 제외 4XX 통신 오류는 3번 재시도
   */
  private _handleError2<R>(retryCount = 3): MonoTypeOperatorFunction<R> {
    let count = 0;
    return retryWhen((e) => {
      return e.pipe(
        mergeMap((v) => {
          if (v.status >= 400 && v.status <= 499) {
            if (count < retryCount - 1) {
              count += 1;
              return of(v);
            }
          }
          return throwError(new Error(this._getErrorMessages2(v)));
        })
      );
    });
  }

  /**
   * 내트워크 오류 메시지 가공
   */
  private _getErrorMessages2({ status, error }: HttpErrorResponse): string {
    if (error?.errors) {
      const e: {
        code: string;
        errors: { [key: string]: string }[];
        message: string;
      } = error;
      let message = `${e.message}[${e.code}]`;
      e.errors.forEach((err) => {
        Object.entries(err).forEach(([key, value]) => {
          message += `\n${key} : ${value}`;
        });
      });
      return message;
    }

    if (status === 400) {
      // api 서버에서 badRequest 응답을 받았을 때. 예로 현 golftour 프로젝트에서 ExceptionHandler 없이 error 를 응답하는 경우 등
      const e: {
        content;
      } = error;
      if (e.content) {
        const m = e.content
          .map((err) => `${err.objectName} : ${err.defaultMessage}`)
          .join('\n');
        return `${m}`;
      }
    }

    if (status === 500) {
      const e: {
        status: number;
        error: string;
        message: string;
      } = error;
      return `${e.error}[${e.status}]\n${e.message}`;
    }
    return `Network Error(${status})`;
  }

  /**
   * 서버에서 응답받은 페이지 형식을 클라이언트 처리 용이하게 변경
   */
  private _parsePage2(serverResponse: any): IPage<any> {
    if ('_embedded' in serverResponse) {
      // T2의 형식이 IHalPageResponse 이면
      const { _links, _embedded, page } = serverResponse as IHalPageResponse;

      return {
        content: (_embedded && _embedded[Object.keys(_embedded)[0]]) ?? [],
        page,
      };
    }
    if ('content' in serverResponse) {
      // T2의 형식이 IPageResponse 이면
      const { content, size, totalElements, totalPages, number } =
        serverResponse as IPageResponse;

      return {
        content,
        page: {
          size,
          totalElements,
          totalPages,
          number,
        },
      };
    }
    return null;
  }
}
