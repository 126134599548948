import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gloria-content-section',
  templateUrl: './gloria-content-section.component.html',
  styleUrls: ['./gloria-content-section.component.scss'],
})
export class GloriaContentSectionComponent implements OnInit {
  #bgImgs: string[];

  /** 컨텐츠 배경 */
  @Input()
  set bgImgs(v: string[]) {
    this.#currentBgIndex = 0;
    this.#bgImgs = v;
  }

  get bgImgs(): string[] {
    return this.#bgImgs;
  }

  /** 배경 이미지 전환 간격 */
  @Input() bgImgDelay = 10000;

  /** 컨텐츠 비율 */
  @Input() sectionRatio: string;

  /** 컨텐츠 배경색 */
  @Input() bgColor: string;

  /** 컨텐츠 배경색 */
  @Input() margin: string;

  /** 컨텐츠 최소높이 */
  @Input() minHeight: string;

  /** 컨텐츠 최대높이 */
  @Input() maxHeight: string;

  /** 하단 구분선 */
  @Input() borderBottom: string;

  /** 컨텐츠 영역 여백 */
  @Input() padding: string;

  /** 현재 배경의 index */
  #currentBgIndex = 0;

  get bgImg(): string {
    if (this.#bgImgs?.length > 0) {
      return this.#bgImgs[this.#currentBgIndex];
    }
    return '';
  }

  constructor() {}

  ngOnInit(): void {
    // bgImgs length 가 1 이상일 땐 5초 간격으로 imgs 배열 인덱스 증가 후 순환
    setInterval(() => {
      if (this.#bgImgs?.length > 0) {
        this.#currentBgIndex = (this.#currentBgIndex + 1) % this.#bgImgs.length;
      }
    }, this.bgImgDelay);
  }
}
