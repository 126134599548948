import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '../core/utils';
import { IMrhst } from '../repository/mrhst/mrhst.model';

/**
 * 템플릿 데이터
 */
export interface TemplateData {
  mrhst?: IMrhst;
}

/**
 * 텍스트 템플릿 뷰어
 */
@Directive({
  selector: '[textTemplateViewer]',
})
export class TextTemplateViewerDirective implements OnChanges {
  /**
   * 현재 언어
   */
  language: string;

  /**
   * 텍스트 템플릿
   */
  @Input() textTemplateViewer: string;

  /**
   * 템플릿 데이터
   */
  @Input() templateData: TemplateData;

  /**
   * 한국어 필드에 접미사가 필요한지 여부
   *
   * @example `mrhstNm` vs `mrhstNmKo`
   */
  @Input() isKoreanNeedSuffix = false;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService
  ) {
    this.language = this.translateService.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // 변경 없으면
    if (!changes.textTemplateViewer && !changes.templateData) {
      // 종료
      return;
    }

    // 하나라도 없으면
    if (!this.textTemplateViewer || !this.templateData) {
      // 종료
      return;
    }

    // 치환된 문자
    const replaced = this.replaceTextToData(
      this.textTemplateViewer,
      this.templateData
    );

    // html 수정
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'innerHTML',
      replaced
    );
  }

  /**
   * 문자열 치환
   * @param text 템플릿
   * @param data 데이터(Mrhst 등)
   * @returns 치환된 문자열
   */
  private replaceTextToData(text: string, data: any): string {
    return text.replace(/#\{([^}]+)\}/g, (match, p1) => {
      const keys: string[] = p1.split('.');
      let value = data;
      // 번역 여부, false이면 notranslate 안붙이으로 구글 자동번역 대상임
      let isTranslated = false;

      keys.forEach((key: string, index: number) => {
        // 언어 접미사 붙인 데이터 키
        const suffixAddedKey = Utils.getDataI18nKey(
          key,
          this.language,
          this.isKoreanNeedSuffix
        );

        // 찾는 언어의 값이 있다면 예) mrhstNmEn
        if (value[suffixAddedKey]) {
          value = value[suffixAddedKey];

          // 이번 키가 마지막 키(객체가 아니고 값이라면)
          if (index === keys.length - 1) {
            // 번역된게 맞음
            isTranslated = true;
          }
        } else {
          value = value[key] || ' ? ';
        }
      });

      // 번역 값 있으면 구글 자동번역 대상 아님
      return `<span ${isTranslated && 'class="notranslate"'} >${value}</span>`;
    });
  }
}
