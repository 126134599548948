import {
  ChangeDetectorRef,
  ElementRef,
  OnDestroy,
  Pipe,
  PipeTransform,
  Renderer2,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Utils } from '../core/utils';

/**
 * 데이터 번역 파이프
 */
@Pipe({
  name: 'dataI18n',
  // true면 번역 변경시 값 변경 안됨
  pure: false,
})
export class DataI18nPipe implements PipeTransform, OnDestroy {
  /**
   * 언어 변경시 구독
   */
  onLangChange: Subscription;

  /**
   * 기본 언어 변경시 구독
   */
  onDefaultLangChange: Subscription;

  /**
   * 번역 된 값
   */
  value: string;

  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.onLangChange?.unsubscribe();
    this.onDefaultLangChange?.unsubscribe();
  }

  /**
   * 데이터 번역
   * @param data 번역 데이터가 포함된 오브젝트
   * @param key 번역 원본 키
   * @param language 언어(입력 안하면 현재 선택된 언어)
   * @example mrhst, 'mrhstNm', 'ko'
   */
  transform(
    data: any,
    key?: string,
    language = this.translateService.currentLang
  ): string {
    // 번역
    this.updateValue(data, key, language);

    // 구독 없으면 구독
    if (!this.onLangChange) {
      this.onLangChange = this.translateService.onLangChange.subscribe(() => {
        this.updateValue(data, key, language);
        this.changeDetectorRef.markForCheck();
      });
    }

    // 구독 없으면 구독
    if (!this.onDefaultLangChange) {
      this.onDefaultLangChange =
        this.translateService.onDefaultLangChange.subscribe(() => {
          this.updateValue(data, key, language);
          this.changeDetectorRef.markForCheck();
        });
    }

    return this.value;
  }

  /**
   * 번역 값 설정
   * @param data 번역 데이터가 포함된 오브젝트
   * @param key 데이터 키
   * @param language 선택 언어
   */
  private updateValue(
    data: any,
    key: string,
    language = this.translateService.currentLang
  ): void {
    this.removeClassNotranslate();

    if (!data) {
      this.value = '';
      return;
    }

    if (typeof data !== 'object') {
      this.value = data;
      return;
    }

    const translated = Utils.getDataI18n(data, key, language, null);

    if (!translated || translated === 'null') {
      this.value =
        Utils.getDataI18n(data, key, this.translateService.defaultLang) || '';
      return;
    }

    this.addClassNotranslate();
    this.value = translated;
  }

  /**
   * 자체 번역 결과가 있는것은 class="notranslate" 추가
   */
  private addClassNotranslate(): void {
    const currentOrParentNode = this.getNodeToNotranslate();

    if (!currentOrParentNode) {
      return;
    }

    this.renderer2.addClass(currentOrParentNode, 'notranslate');
  }

  /**
   * 자체 번역 결과가 없는것은 class="notranslate" 삭제
   */
  private removeClassNotranslate(): void {
    const currentOrParentNode = this.getNodeToNotranslate();

    if (!currentOrParentNode) {
      return;
    }

    this.renderer2.removeClass(currentOrParentNode, 'notranslate');
  }

  /**
   * class="notranslate" 추가 할 엘리먼트 반환
   */
  private getNodeToNotranslate(): Node {
    const maybeTextNode: Node = this.elementRef.nativeElement;

    return maybeTextNode?.nodeName === '#text'
      ? maybeTextNode.parentNode
      : maybeTextNode;
  }
}
